import React from 'react';
import { connect } from 'react-redux';
import { IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

import { authActions } from '$gbusiness/redux/auth';
import { ROUTES as GROUTES } from '$gbusiness/enums';
import { ROUTES } from '$business/enums';

import { configs, features } from '$configs';
import Menu from '$components/sideMenu';

import AppOutlet from '$product/_init/appOutlet';
import FactoryModel from '$fbusiness/models/factory';
import { getFactoryLogo, initialDateFilter } from '$fbusiness/helpers/util';
import UserModel from '$gbusiness/models/user';
import CurrentStateModel, { initialCurrentState } from '$fbusiness/models/currentState';
import DateFilterModel from '$fbusiness/models/dateFilter';
import StorePane from '../../../screens/invoicesScreen/storePane';

interface RouterProps {
  isLoggedIn: boolean;
  currentRoute: string;
  user: UserModel;
  dateFilter: DateFilterModel;
  onLogout: Function;
  factory: FactoryModel;
  factories?: Array<FactoryModel>;
}

const Router: React.FC<RouterProps> = React.memo(
  ({ factory, factories, isLoggedIn, currentRoute, user, dateFilter, onLogout }) => {
    const redirectRoute = isLoggedIn ? ROUTES.DEFAULT_APP_PATH : GROUTES.DEFAULT_PUBLIC_PATH;
    // const currentRoute = window.location.pathname;
    const currentState: CurrentStateModel = {
      ...initialCurrentState,
      isLoggedIn,
      user,
      hidePrice: false,
      isFactory: true,
      factoryId: factory.id || 0,
      settings: factory.settings,
    };
    return (
      <IonReactRouter basename={process.env.REACT_APP_PUBLIC_URL}>
        {
          <IonSplitPane contentId="main" when={`(min-width: ${configs.display.splitPaneThreshold})`}>
            {features.useMenu && (
              <Menu
                logo={getFactoryLogo(factory)}
                currentRoute={currentRoute}
                currentState={currentState}
                onLogout={onLogout}
                user={user}
                factories={factories}
                isLoggedIn={isLoggedIn}
              />
            )}
            <AppOutlet
              dateFilter={dateFilter || initialDateFilter}
              currentState={currentState}
              isLoggedIn={isLoggedIn}
              userLevel={user?.userLevel}
            />
            <StorePane route={currentRoute} currentState={currentState} />
          </IonSplitPane>
        }
        <Route path="/" render={() => <Redirect to={redirectRoute} />} exact />
      </IonReactRouter>
    );
  },
  (pp, np) =>
    pp.isLoggedIn === np.isLoggedIn &&
    pp.currentRoute === np.currentRoute &&
    pp.user.other?.factoryId === np.user.other?.factoryId &&
    pp.user.userLevel === np.user.userLevel,
);

const mapStateToProps = (state) => ({
  isLoggedIn: state.localStorage.accessToken !== '',
  dateFilter: state.localStorage.dateFilter,
  factories: state.factory.factories,
  currentRoute: state.routing.currentRoute,
  user: state.currentUser,
  factory: state.factory.factory,
});

const mapDispatchToProps = {
  onLogout: authActions.logout,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(Router);
