import React, { useEffect, useState } from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';
import { Formik } from 'formik';

import { Flex, PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';

import CommRuleModel from '$fbusiness/models/commRule';
import { FormSection } from '$gcomponents/reusables';
import { COMMRULE_FORM } from './commRuleForm';
import { input } from '$gbusiness/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { factoryActions } from '$fbusiness/redux/factory';
import { AppModel } from '$business/redux';
import Footer from '$gcomponents/widgets/footer';
import CurrencyInput from '$gcomponents/primitives/currencyInput';
import { toNumber } from '$gbusiness/helpers/util';
import { DISCOUNT_RULE } from '$fbusiness/enums/options/discountRule';
import CurrentStateModel from '$fbusiness/models/currentState';
import { getAccess, isAccessible } from '$fbusiness/helpers/util';

interface CommRuleDetailsProps {
  onClose: Function;
  commRule: CommRuleModel;
  currentState: CurrentStateModel;
}

const CommRuleDetails: React.FC<CommRuleDetailsProps> = ({ commRule, onClose, currentState }) => {
  const { settings } = commRule;
  const dispatch = useDispatch();
  const [discountRule, setDiscountRule] = useState({
    type: settings?.enableDiscount ? DISCOUNT_RULE.RULE : settings?.type,
    enableDiscount: settings?.enableDiscount ? true : false,
    commission1: settings?.commission1 || '',
    commission2: settings?.commission2 || '',
    discountRate1: settings?.discountRate1 || '',
    discountRate2: settings?.discountRate2 || '',
  });
  const isFinished = useSelector((state: AppModel) => state.factory.isFinished);
  const ACCESS = getAccess(currentState);

  useEffect(() => {
    if (!isFinished) return;
    dispatch(factoryActions.resetFinished());
    onClose();
  }, [isFinished, onClose, dispatch]);

  const onSubmit = (values) => {
    const { id } = commRule;
    const newValues = {
      ...values,
      ...(values?.endDay && { endDay: parseInt(values?.endDay) }),
      ...(values?.startDay && { startDay: parseInt(values?.startDay) }),
      discountRate:
        values.discountType === DISCOUNT_RULE.FIXED ? toNumber(values.discountRate) : toNumber(values.rate),
      settings: {
        ...settings,
        ...discountRule,
      },
    };
    dispatch(factoryActions.saveCommRule(id, newValues));
  };

  const updateDiscountRule = (newValue = {}) => {
    setDiscountRule({
      ...discountRule,
      ...newValue,
    });
  };

  const onChangeDiscountType = (type) => {
    setDiscountRule({
      ...discountRule,
      type,
      enableDiscount: type === DISCOUNT_RULE.RULE ? true : false,
    });
  };

  const commRuleForm = COMMRULE_FORM(onChangeDiscountType);

  const validateForm = (values) => {
    input.validateError(commRuleForm, values);
  };
  const { id, name, rate, discountRate } = commRule;

  const canSave = isAccessible(
    id ? ACCESS.ACTION.SETTING.COMMISSION_RULE.EDIT : ACCESS.ACTION.SETTING.COMMISSION_RULE.CREATE,
    currentState,
  );

  return (
    <Formik
      initialValues={{
        name,
        rate,
        discountRate: discountRate,
        discountType: discountRule.type,
      }}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <PageWrapper>
              <FormSection FORM={commRuleForm} formik={formik} marginBottom="15px" />
              {/* <Flex>
                <Checkbox checked={discountRule.enableDiscount || false} onChange={activateDiscountRule} />{' '}
                Enable Discount Rule
              </Flex> */}
              {discountRule.enableDiscount && (
                <>
                  <Flex>
                    <CurrencyInput
                      isPercent
                      className="comm-rate"
                      label=""
                      value={discountRule.commission1}
                      onChange={(value) => updateDiscountRule({ commission1: toNumber(value) })}
                    />
                    commission rate when discount rate is under
                    <CurrencyInput
                      isPercent
                      className="discount-rate"
                      label=""
                      value={discountRule.discountRate1}
                      onChange={(value) => updateDiscountRule({ discountRate1: toNumber(value) })}
                    />
                  </Flex>
                  <Flex>
                    <CurrencyInput
                      isPercent
                      className="comm-rate"
                      label=""
                      value={discountRule.commission2}
                      onChange={(value) => updateDiscountRule({ commission2: toNumber(value) })}
                    />
                    commission rate when discount rate is above
                    <CurrencyInput
                      isPercent
                      className="discount-rate"
                      label=""
                      value={discountRule.discountRate2}
                      onChange={(value) => updateDiscountRule({ discountRate2: toNumber(value) })}
                    />
                  </Flex>
                </>
              )}
            </PageWrapper>
          </IonContent>
          <Footer justifyContent="center">
            <Button className="half" onClick={formik.handleSubmit} disabled={!formik.isValid || !canSave}>
              {intl('BUTTON.SUBMIT')}
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default CommRuleDetails;
