import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { screen } from '$fcomponents/hoc';
import intl from '$intl';
import OptionModel from '$gbusiness/models/option';
import ItemActionModel from '$gbusiness/models/itemAction';
import { Link } from '$gcomponents/primitives';
import { categoryActions } from '$fbusiness/redux/category';
import { departmentActions } from '$fbusiness/redux/department';
import { itemActions } from '$fbusiness/redux/item';
import { dialog } from '$gcomponents/reusables';
import { Desktop, Footer } from '$gstyles/wrapper';
import DepartmentModel from '$fbusiness/models/department';
import CategoryModel from '$fbusiness/models/category';
import ItemModel from '$fbusiness/models/item';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import { Header, TableView2 } from '$gcomponents/widgets';
import SummaryModal from '$fbusiness/models/inventorySummary';
import ImportItemModal from './importItemModal';
import FactoryModel from '$fbusiness/models/factory';
import { FACTORY_TYPE } from '$fbusiness/enums/options/factoryType';

import ItemsHeader from './header';
import ItemsFilter from './items/filter';
import { ITEMS_TABLE_CONFIG, ITEMS_TABLE } from './items/table';
import { defaultItemColumns, PRODUCTS_TABLE } from './items/productTable';
import { DEPTS_TABLE, DEPTS_TABLE_CONFIG } from './departments/table';
import { CATEGORY_TABLE, CATEGORY_TABLE_CONFIG } from './categories/table';
import { IonPageWrapper } from './styles';
import { configs } from '$configs';
import { ITEMS } from '$fbusiness/enums/columns';
import PATH from '$business/enums/paths';
import { DeleteOutline } from '@mui/icons-material';
import FabButton from '$fcomponents/fabButton';
import { getAccess, isAccessible } from '$fbusiness/helpers/util';

interface ItemListScreenProps {
  match;
  history;
  columns;
  pageSizes;
  factory: FactoryModel;
  departments: Array<DepartmentModel>;
  categories: Array<CategoryModel>;
  flattenedCategories: Array<CategoryModel>;
  items: Array<ItemModel>;
  fetchItems: Function;
  fetchCategories: Function;
  fetchDepartments: Function;
  onHydrate: Function;
  toggleProp: Function;
  toggleSettingProp: Function;
  saveDepartment: Function;
  saveCategory: Function;
  saveItem: Function;
  autoFetch?: boolean;
  deleteItem;
  deleteItems;
  deleteCategory;
  deleteDepartment;
  reorderDepartment;
  currentState;
}

const TABS: Array<OptionModel> = [
  { label: 'SCREEN.INVENTORY.TABS.OVERVIEW', value: '' },
  { label: 'SCREEN.INVENTORY.TABS.DEPARTMENTS', value: 'departments' },
  { label: 'SCREEN.INVENTORY.TABS.CATEGORIES', value: 'categories' },
  { label: 'SCREEN.INVENTORY.TABS.ITEMS', value: 'items' },
];

const TTABS: Array<OptionModel> = [
  { label: 'SCREEN.PRODUCTS.TABS.ALL', value: '' },
  { label: 'SCREEN.PRODUCTS.TABS.EBROCHURE', value: 'ebrochure' },
  { label: 'SCREEN.PRODUCTS.TABS.OUT_OF_STOCK', value: 'out' },
  { label: 'SCREEN.PRODUCTS.TABS.HIDDEN', value: 'hidden' },
];

const inititalFilter = {
  query: '',
  tableTabIndex: 0,
  forceRefresh: false,
};

const ItemListScreen: React.FC<ItemListScreenProps> = ({
  history,
  factory,
  match,
  departments,
  categories,
  flattenedCategories,
  items,
  toggleProp,
  toggleSettingProp,
  fetchItems,
  fetchCategories,
  fetchDepartments,
  deleteItem,
  deleteItems,
  deleteCategory,
  deleteDepartment,
  saveDepartment,
  saveCategory,
  saveItem,
  columns,
  pageSizes,
  currentState,
}) => {
  const {
    params: { tabIndex },
  } = match;
  const {
    type: factoryType,
    settings: { productSize },
  } = factory;
  const shouldFetchData = productSize && productSize > configs.productSizeThreshold;

  const isBakery = factoryType === FACTORY_TYPE.BAKERY;
  const [tempItems, setTempItems] = useState([]);
  const [filter, setFilter] = useState(inititalFilter); // used by ecommerce
  const [currentTabIndex, setCurrentTabIndex] = useState(isBakery ? parseInt(tabIndex) : 0);
  const [selections, setSelections] = useState<Array<any>>([]);
  const [totalItemCount, setTotalItemCount] = useState(items?.length || 0);
  const [showItemImport, setShowItemImport] = useState(false);
  const {
    location: { state },
  } = history;
  const ACCESS = getAccess(currentState);

  // SET ACCESS
  let canDrag = true;
  let canEdit = true;
  let canDelete = true;
  let canCreate = true;
  if (currentTabIndex === 1) {
    canCreate = isAccessible(ACCESS.ACTION.DEPARTMENT.CREATE, currentState);
    canEdit = isAccessible(ACCESS.ACTION.DEPARTMENT.EDIT, currentState);
    canDelete = isAccessible(ACCESS.ACTION.DEPARTMENT.DELETE, currentState);
  } else if (currentTabIndex === 2) {
    canDrag = isAccessible(ACCESS.ACTION.CATEGORY.RANK, currentState);
    canCreate = isAccessible(ACCESS.ACTION.CATEGORY.CREATE, currentState);
    canEdit = isAccessible(ACCESS.ACTION.CATEGORY.EDIT, currentState);
    canDelete = isAccessible(ACCESS.ACTION.CATEGORY.DELETE, currentState);
  } else {
    canDrag = isAccessible(ACCESS.ACTION.ITEM.RANK, currentState);
    canCreate = isAccessible(ACCESS.ACTION.ITEM.CREATE, currentState);
    canEdit = isAccessible(ACCESS.ACTION.ITEM.EDIT, currentState);
    canDelete = isAccessible(ACCESS.ACTION.ITEM.DELETE, currentState);
  }

  useEffect(() => {
    if (state?.autoFetch) reloadTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.autoFetch]);

  const handleRouteChange = (index) => {
    setCurrentTabIndex(index);
    history.replace(PATH.INVENTORY + '/' + index || '');
  };

  const tableTabs = {
    TABS: TTABS,
    index: filter?.tableTabIndex,
    variant: 'outlined',
    onChange: (newTableIndex) => {
      updateFilter({ tableTabIndex: newTableIndex, status: TTABS[newTableIndex].value });
    },
    color: 'primary' as 'primary' | 'secondary',
    itemWidth: '60px',
  };

  const updateFilter = (newFilter: any = undefined) => {
    setFilter({
      ...filter,
      ...(newFilter ? { ...newFilter, onlyRefresh: false } : { onlyRefresh: true }),
      forceRefresh: !filter.forceRefresh,
    });
  };

  const reloadTable = () => {
    if (shouldFetchData) {
      updateFilter({});
      return;
    }
    switch (currentTabIndex) {
      case 1:
        fetchDepartments();
        break;
      case 2:
        fetchCategories();
        break;
      default:
        fetchItems();
        break;
    }
  };

  const onTableLoad = (a, b, c) => {
    setTotalItemCount(a);
    if (c.list) setTempItems(c.list);
  };

  const deleteRow = async (id) => {
    let deleteThis;
    switch (currentTabIndex) {
      case 1:
        deleteThis = deleteDepartment;
        break;
      case 2:
        deleteThis = deleteCategory;
        break;
      default:
        deleteThis = deleteItem;
        break;
    }
    await deleteThis(id);
  };

  const handleDelete = async (row) => {
    dialog.confirm({
      message: 'MESSAGE.DELETE_WARNING',
      onPress: async () => {
        await deleteRow(row.id);
        reloadTable();
      },
    });
  };

  const onImport = (row) => {
    setShowItemImport(true);
  };

  const onToggleHide = async (row) => {
    await toggleProp(row.id, { hide: !row.hide });
    updateFilter();
  };
  const onToggleEbrochure = async (row) => {
    await toggleProp(row.id, { ebrochure: !row.ebrochure });
    updateFilter();
  };
  const onTogglePromo = async (row) => {
    await toggleSettingProp(row.id, { ...row.settings, promo: !row.settings.promo });
    updateFilter();
  };

  const itemActions: Array<ItemActionModel> = [
    {
      label: 'BUTTON.EDIT',
      eventName: 'editItem',
      disabled: () => !canEdit,
      onClick: (row) => {
        history.push(row.route + '/' + row.id);
      },
    },
    {
      label: 'BUTTON.DELETE',
      eventName: 'deleteItem',
      disabled: () => !canDelete,
      onClick: handleDelete,
    },
  ];

  let filterSection;
  let tableConfig;
  let table;
  let data;
  let buttonComponent: any = null;
  let onSelection;
  let itemColumns;

  const generateAddButton = (route, item) => {
    return (
      <Link route={route + '/0'} className={canCreate ? '' : 'disabled o40'}>
        <Button
          variant="contained"
          color="primary"
          startIcon={
            <Desktop alignItems="center" className="flex">
              <AddIcon />
            </Desktop>
          }>
          {intl('ITEM.NEW', { item })}
        </Button>
      </Link>
    );
  };

  const pageSize = pageSizes ? pageSizes[ITEMS] || configs.display.pageSize || 20 : 0;
  switch (currentTabIndex) {
    case 1:
      buttonComponent = generateAddButton(PATH.DEPARTMENTS, intl('COMMON.DEPARTMENT'));
      filterSection = (
        <ItemsFilter
          totalItemCount={totalItemCount}
          onImport={onImport}
          factory={factory}
          currentState={currentState}
          onSearch={updateFilter}
          addButton={buttonComponent}
        />
      );
      tableConfig = DEPTS_TABLE_CONFIG;
      table = DEPTS_TABLE;
      data = departments.filter((i) => i.name.toLowerCase().includes(filter.query.toLowerCase()));
      break;
    case 2:
      buttonComponent = generateAddButton(PATH.CATEGORIES, intl('COMMON.CATEGORY'));
      filterSection = (
        <ItemsFilter
          onImport={onImport}
          totalItemCount={totalItemCount}
          currentState={currentState}
          factory={factory}
          onSearch={updateFilter}
          addButton={buttonComponent}
        />
      );
      tableConfig = CATEGORY_TABLE_CONFIG;
      table = CATEGORY_TABLE;
      data = categories.filter((i) => {
        if (i.name.toLowerCase().includes(filter.query.toLowerCase())) return true;
        if ((i.depts || []).find((d) => d.name.toLowerCase().includes(filter.query.toLowerCase())))
          return true;
        return false;
      });
      break;
    // @ts-ignore
    case 0:
      buttonComponent = generateAddButton(PATH.ITEMS, intl('COMMON.ITEM'));
      const summaries: Array<SummaryModal> = [
        {
          icon: StorefrontIcon,
          title: departments.length.toString(),
          subtitle: intl('SCREEN.INVENTORY.TABS.DEPARTMENTS'),
        },
        {
          icon: FolderOpenIcon,
          title: categories.length.toString(),
          subtitle: intl('SCREEN.INVENTORY.TABS.CATEGORIES'),
        },
        {
          icon: RestaurantIcon,
          title: items.length.toString(),
          subtitle: intl('SCREEN.INVENTORY.TABS.ITEMS'),
        },
        {
          icon: VisibilityOffOutlinedIcon,
          title: items.filter((i) => i.hide).length.toString(),
          subtitle: intl('SCREEN.INVENTORY.TABS.HIDDEN_ITEMS'),
        },
      ];
      filterSection = (
        <ItemsFilter
          categories={flattenedCategories}
          totalItemCount={totalItemCount}
          onImport={onImport}
          currentState={currentState}
          factory={factory}
          onSearch={updateFilter}
          addButton={buttonComponent}
          summaries={isBakery ? summaries : undefined}
          shouldFetch={shouldFetchData}
        />
      );
    // eslint-disable-next-line no-fallthrough
    default:
      buttonComponent = generateAddButton(PATH.ITEMS, intl('COMMON.ITEM'));
      filterSection = filterSection || (
        <ItemsFilter
          onImport={onImport}
          totalItemCount={totalItemCount}
          currentState={currentState}
          factory={factory}
          onSearch={updateFilter}
          addButton={buttonComponent}
          shouldFetch={shouldFetchData}
        />
      );
      itemColumns = columns ? columns[ITEMS] || defaultItemColumns : null;
      tableConfig = ITEMS_TABLE_CONFIG;
      table = isBakery
        ? ITEMS_TABLE(onToggleHide)
        : PRODUCTS_TABLE(factory, onToggleHide, onToggleEbrochure, onTogglePromo, currentState);
      if (!shouldFetchData) {
        data = items.filter((i) => {
          if (!i.name) return false;
          if (i.name.toLowerCase().includes(filter.query.toLowerCase())) return true;
          if ((i.categories || []).find((d) => d.name.toLowerCase().includes(filter.query.toLowerCase())))
            return true;
          return false;
        });
      }
      onSelection = setSelections;
      break;
  }

  const reassignRank = (list, sourceIndex, destIndex) => {
    if (sourceIndex === destIndex) return list;
    const direction = destIndex > sourceIndex ? 'DOWN' : 'UP';

    const beforeData = direction === 'UP' ? list[destIndex - 1] : list[destIndex];
    const afterData = direction === 'UP' ? list[destIndex] : list[destIndex + 1];
    const beforeRank = beforeData?.rank || 0;
    const afterRank = afterData?.rank || null;

    const newRank = afterRank === null ? Math.round(beforeRank + 1) : (beforeRank + afterRank) / 2;
    return newRank;
  };

  const onDrag = async (result) => {
    const { source, destination, draggableId } = result;
    if (source.index === destination.index) return;
    let newRank;
    switch (currentTabIndex) {
      case 1:
        newRank = reassignRank(departments, source.index, destination.index);
        await saveDepartment(parseInt(draggableId), { rank: newRank }, true);
        break;
      case 2:
        newRank = reassignRank(categories, source.index, destination.index);
        await saveCategory(parseInt(draggableId), { rank: newRank }, true);
        break;
      default:
        newRank = reassignRank(tempItems, source.index, destination.index);
        await saveItem(parseInt(draggableId), { rank: newRank }, true);
        break;
    }
  };

  const deleteSelected = async () => {
    dialog.confirm({
      message: 'MESSAGE.DELETE_WARNING',
      onPress: async () => {
        // for (const i in selections) {
        //   await deleteRow(selections[i]);
        // }
        const ids = data
          ? selections.reduce((acc: any, s, i) => {
              if (!s) return acc;
              acc.push(data[i]?.id);
              return acc;
            }, [])
          : selections.map((i) => i?.id);
        await deleteItems(ids);
        await reloadTable();
        setSelections([]);
      },
    });
  };

  if (isBakery) {
    return (
      <IonPageWrapper>
        <ItemsHeader TABS={TABS} index={currentTabIndex} onChangeIndex={handleRouteChange} />
        <IonContent>
          <TableView2
            tableConfig={tableConfig}
            TABLE={table}
            onDrag={canDrag ? onDrag : undefined}
            data={data}
            onTableLoad={onTableLoad}
            pageSize={pageSize}
            columns={itemColumns}
            columnKey={ITEMS}
            filter={shouldFetchData ? filter : null}
            itemActions={itemActions}
            onSelection={onSelection}>
            <FilterSection title={intl(TABS[currentTabIndex].label)}></FilterSection>
            {filterSection}
          </TableView2>
          <FabButton
            icon={<DeleteOutline />}
            color="danger"
            ver="bottom"
            isHidden={!selections.filter((s) => s).length}
            onClick={deleteSelected}
          />
          <ImportItemModal
            factory={factory}
            refreshTable={shouldFetchData ? () => updateFilter() : undefined}
            show={showItemImport}
            onClose={() => setShowItemImport(false)}
          />
        </IonContent>
        <Footer height="20px" />
      </IonPageWrapper>
    );
  } else {
    return (
      <IonPageWrapper>
        <Header title="SCREEN.PRODUCTS.TITLE" />
        <IonContent>
          <TableView2
            tableConfig={tableConfig}
            TABLE={table}
            onDrag={canDrag ? onDrag : undefined}
            data={data}
            columns={itemColumns}
            columnKey={ITEMS}
            onTableLoad={onTableLoad}
            pageSize={pageSize}
            filter={shouldFetchData ? filter : null}
            itemActions={itemActions}
            onSelection={onSelection}
            tabs={tableTabs}>
            <FilterSection>{filterSection}</FilterSection>
          </TableView2>
          <FabButton
            icon={<DeleteOutline />}
            color="danger"
            ver="bottom"
            isHidden={!selections.filter((s) => s).length}
            onClick={deleteSelected}
          />
          <ImportItemModal
            factory={factory}
            refreshTable={shouldFetchData ? () => updateFilter() : undefined}
            show={showItemImport}
            onClose={() => setShowItemImport(false)}
          />
        </IonContent>
        <Footer height="20px" />
      </IonPageWrapper>
    );
  }
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  user: state.user,
  departments: state.department.departments,
  categories: state.category.categories,
  columns: state.localStorage.columns,
  pageSizes: state.localStorage.pageSize,
  flattenedCategories: state.category.flat,
  items: state.item.items,
  resetOnRoute: false,
});

const mapDispatchToProps = {
  // onHydrate: params => initActions.fetchMyFactory(),
  reorderDepartment: departmentActions.reorder,
  toggleProp: itemActions.toggleProp,
  toggleSettingProp: itemActions.toggleSettingProp,
  fetchItems: itemActions.fetchItems,
  fetchCategories: categoryActions.fetchCategories,
  fetchDepartments: departmentActions.fetchDepartments,
  saveItem: itemActions.saveItem,
  saveCategory: categoryActions.saveCategory,
  saveDepartment: departmentActions.saveDepartment,
  deleteCategory: categoryActions.deleteCategory,
  deleteDepartment: departmentActions.deleteDepartment,
  deleteItem: itemActions.deleteItem,
  deleteItems: itemActions.deleteItems,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ItemListScreen));
