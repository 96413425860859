import React from 'react';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';

import { configs, features } from '$configs';
import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { Button, Select } from '@mui/material';
import { format } from '$gbusiness/helpers/date';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import {
  KEYS as ORDER_STATUS,
  DELIVERED_OPTIONS,
  DELIVERED_OPTIONS2,
  PREPARING_OPTIONS,
  FLAGGED_OPTIONS,
  SHIPPED_OPTIONS,
  CLOSED_OPTIONS,
  PREPARING_OPTIONS2,
} from '$fbusiness/enums/options/orderStatus';

import { deriveRawToOrder } from '$fbusiness/models/order';
import { currency } from '$gbusiness/helpers/util';
import intl from '$intl';
import { generateSelectOptions } from '$gcomponents/utils/input';
import { accessDisable, accessHide, getAccess, isAccessible } from '$fbusiness/helpers/util';

export const ORDER_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.order.general,
    deriveToModel: deriveRawToOrder,
    method: 'GET',
    defaultSortKey: 'updatedAt',
    defaultSortOrder: SORT.DSC,
    pageSize: 20,
    autoRefreshInterval: 30,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 400,
    maxWidth: 1100,
    exclusion: 'orders',
    cellPadding: ` 8px 4px`,
    responsive: true,
    isStripped: true,
    rowHeight: 44,
    headerHeight: 64,
  },
};

export const generateConfig = (onClickRow) => {
  return {
    ...ORDER_TABLE_CONFIG,
    dataSource: {
      ...ORDER_TABLE_CONFIG.dataSource,
      onClickRow,
    },
  };
};

export const ORDER_TABLE = ({
  onDetails,
  onChangeStatus,
  onReceiving,
  currentState,
  isSalesman,
  settings,
  userLevel = 3,
  hidePrice = false,
  isFactory = false,
}): Array<CellModel> => {
  const ACCESS = getAccess(currentState);
  return [
    {
      label: 'SCREEN.ORDERS.COLS.ID',
      value: 'orderId',
      alwaysShow: true,
      className: 'main',
      width: 120,
    },
    {
      label: 'SCREEN.ORDERS.COLS.STORE',
      value: 'storeName',
      alwaysShow: true,
      // sortable: SORT.ASC,
      width: 300,
    },
    {
      label: 'SCREEN.ORDERS.COLS.ORDER_DATE',
      value: 'createdAt',
      component: (row) => <>{format(row.createdAt, DATE_FORMATS.DATE_WEEKDAY) || ''}</>,
      sortable: SORT.ASC,
      width: 200,
    },
    {
      label: 'SCREEN.ORDERS.COLS.DELIVERY_DATE',
      value: 'deliveryDate',
      component: (row) => <>{format(row.deliveryDate, DATE_FORMATS.DATE_WEEKDAY) || ''}</>,
      sortable: SORT.ASC,
      width: 200,
    },
    {
      label: 'SCREEN.ORDERS.COLS.STATUS',
      isHidden: settings.hidePoStatus || isFactory,
      value: 'status',
      className: 'no-label',
      component: (row) => {
        const options =
          settings?.pickupOnly && row.status === ORDER_STATUS.READY
            ? DELIVERED_OPTIONS2
            : row.status === ORDER_STATUS.SHIPPED
            ? DELIVERED_OPTIONS
            : null;
        return options ? (
          <Select
            disabled={!!features.disableStatus}
            variant="outlined"
            value={row.status}
            className="xsmall"
            onChange={(e) => onChangeStatus(row, e.target.value)}>
            {generateSelectOptions(options, row.status)}
          </Select>
        ) : (
          <div>{intl('INPUT.OPTION.ORDER_STATUS.' + row.status)}</div>
        );
      },
      sortable: SORT.ASC,
      width: 200,
    },
    {
      isHidden: settings.hidePoStatus || !isFactory,
      label: 'SCREEN.ORDERS.COLS.STATUS',
      value: 'status',
      className: 'no-label',
      component: (row) => {
        let options;
        switch (row.status) {
          case ORDER_STATUS.PREPARING:
            options = settings?.pickupOnly ? PREPARING_OPTIONS2 : PREPARING_OPTIONS;
            break;
          case ORDER_STATUS.READY:
            options = PREPARING_OPTIONS2;
            break;
          case ORDER_STATUS.COMPLETED:
            options = PREPARING_OPTIONS2;
            break;
          case ORDER_STATUS.FLAGGED:
            options = FLAGGED_OPTIONS;
            break;
          case ORDER_STATUS.SHIPPED:
            options = SHIPPED_OPTIONS;
            break;
          case ORDER_STATUS.CLOSED:
            options = CLOSED_OPTIONS;
            break;
          case ORDER_STATUS.DELIVERED:
            options = isFactory ? DELIVERED_OPTIONS : undefined;
            break;
          default:
            options = [];
            break;
        }
        if (userLevel >= 3)
          options = options.map((o) => ({
            ...o,
            ...(o.value !== ORDER_STATUS.SHIPPED && { disabled: true }),
          }));
        return options ? (
          <Select
            variant="outlined"
            className={`xsmall ${accessDisable(ACCESS.ACTION.ORDER.CHANGE_STATUS)}`}
            value={row.status}
            onChange={(e) => onChangeStatus(row, e.target.value)}>
            {generateSelectOptions(options, row.status, true)}
          </Select>
        ) : (
          <div>{intl('INPUT.OPTION.ORDER_STATUS.' + row.status)}</div>
        );
      },
      sortable: SORT.ASC,
      width: 200,
    },
    {
      label: 'SCREEN.ORDERS.COLS.QTY',
      value: 'qty',
      align: 'center',
      sortable: SORT.ASC,
      width: 150,
    },
    {
      label: 'SCREEN.ORDERS.COLS.TOTAL',
      value: 'total',
      align: 'center',
      className: accessHide(ACCESS.MONEY.VIEW),
      component: (row) => <>{currency(row.total)}</>,
      sortable: SORT.ASC,
      width: 150,
    },
    {
      label: 'COMMON.REFUND',
      value: 'refund',
      align: 'center',
      className: accessHide(ACCESS.MONEY.VIEW),
      component: (row) => <>{row.refund ? currency(row.refund?.total) : ''}</>,
      sortable: SORT.ASC,
      width: 150,
    },
    {
      label: 'SCREEN.ORDERS.COLS.DETAIL',
      align: 'center',
      className: 'action',
      isHidden: !isAccessible(ACCESS.ACTION.ORDER.VIEW, currentState),
      value: '',
      component: (row) => <ReceiptOutlinedIcon className="pointer" onClick={() => onDetails(row)} />,
      width: 150,
    },
    {
      isHidden: isFactory,
      label: '',
      value: 'receiving',
      className: 'action',
      component: (row) =>
        row.status === ORDER_STATUS.DELIVERED || row.status === ORDER_STATUS.COMPLETED ? (
          <Button size="small" variant="text" color="primary" onClick={() => onReceiving(row)}>
            {intl('SCREEN.ORDERS.COLS.RECEIVING')}
          </Button>
        ) : null,
      width: 200,
    },
  ];
};
