import React, { useState } from 'react';
import intl from '$intl';

import { LabelCol, LabelHeader, LabelInput, LabelRow, LabelTableWrapper } from './styles';
import { Button } from '$gcomponents/primitives';
import { Delete } from '@mui/icons-material';
import { accessDisable, accessHide, getAccess } from '$fbusiness/helpers/util';
import CurrentStateModel from '$fbusiness/models/currentState';

interface LabelTableProps {
  enums: Array<any>;
  width?: string;
  onSubmit: Function;
  currentState: CurrentStateModel;
}

const LabelTable: React.FC<LabelTableProps> = ({ enums, width = '400px', onSubmit, currentState }) => {
  const [newEnums, setNewEnums] = useState<any>(enums);
  const ACCESS = getAccess(currentState);

  const onChangeInput = (index, evt, type) => {
    setNewEnums(
      newEnums.map((e, i) => {
        if (i !== index) return e;
        const val = evt.target.value;
        return {
          ...e,
          [type]: val,
        };
      }),
    );
  };

  const onAddRow = () => {
    if (!newEnums) return;
    setNewEnums([...newEnums, { label: '', value: '', fixed: 0 }]);
  };

  const onDeleteRow = (index) => {
    setNewEnums(newEnums.filter((e, i) => index !== i));
  };

  const isFormInValid = () => {
    const required = newEnums.find((e) => !e.label || !e.value);
    if (required) return 'REQUIRED';

    const values = newEnums.map((e) => e.value);
    if (new Set(values).size !== values.length) return 'DUP';

    return false;
  };

  return (
    <LabelTableWrapper width={width}>
      <LabelHeader>
        <LabelCol>{intl('SCREEN.LABELS.LABEL')}</LabelCol>
        <LabelCol>{intl('SCREEN.LABELS.VALUE')}</LabelCol>
      </LabelHeader>
      {newEnums.map((status, i) => {
        return (
          <LabelRow key={i}>
            <LabelCol>
              <LabelInput onChange={(e) => onChangeInput(i, e, 'label')} type="text" value={status.label} />
            </LabelCol>
            <LabelCol>
              <LabelInput
                onChange={(e) => onChangeInput(i, e, 'value')}
                type="text"
                value={status.value}
                disabled={status.fixed}
              />
            </LabelCol>
            <LabelCol>
              {!status.fixed && (
                <Delete
                  className={`pointer ${accessHide(ACCESS.ACTION.SETTING.SERIAL_STATUS.DELETE)}`}
                  onClick={() => onDeleteRow(i)}
                />
              )}
            </LabelCol>
          </LabelRow>
        );
      })}
      <div>
        <Button
          size="small"
          variant="text"
          onClick={onAddRow}
          className={accessHide(ACCESS.ACTION.SETTING.SERIAL_STATUS.DELETE)}>
          {intl('SCREEN.LABELS.BUTTON_NEW')}
        </Button>
      </div>
      <div className="submit-wrapper">
        <Button
          onClick={() => onSubmit(newEnums)}
          disabled={!!isFormInValid()}
          className={accessDisable(ACCESS.ACTION.SETTING.SERIAL_STATUS.EDIT)}>
          {intl('BUTTON.SUBMIT')}
        </Button>
      </div>
    </LabelTableWrapper>
  );
};

export default LabelTable;
