import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import { Search, CloudUpload } from '@mui/icons-material';

import intl from '$intl';
import { IconInputWrapper } from '$gstyles/input';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { FilterWrapper, SummarySection } from '../styles';
import SummaryIcon from './summaryIcon';
import SummaryModal from '$fbusiness/models/inventorySummary';
import ExportButton from '$gcomponents/reusables/exportButton';

import { Flex } from '$gstyles/wrapper';
import { Col, Div, Row, SPACE } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import { getExportSetting } from '../importItemModal/previewTable';
import { Link } from '$gstyles/general';

import SampleItemsBakery from '../importItemModal/sample_items.json';
import SampleItemsLs from '../importItemModal/sample_items_ls.json';

import CategoryModel from '$fbusiness/models/category';
import FactoryModel from '$fbusiness/models/factory';
import { FACTORY_TYPE } from '$fbusiness/enums/options/factoryType';
import CurrentStateModel from '$fbusiness/models/currentState';
import { accessDisable, getAccess } from '$fbusiness/helpers/util';
const { debounce } = _;
interface FilterInputsProps {
  categories?: Array<CategoryModel>;
  onSearch: Function;
  shouldFetch?: boolean;
  totalItemCount?: number;
  onTableTabChange?: Function;
  parentFilter?: FilterInputsProps;
  summaries?: Array<SummaryModal>;
  currentState: CurrentStateModel;
  factory?: FactoryModel;
  tabIndex?: number;
  excludeImport?: boolean;
  addButton?: React.ReactNode;
  onImport?: Function;
}

interface filterProps {
  query: string;
  categoryId?: any;
  forceRefresh?: boolean;
}

const defaultFilter: filterProps = {
  query: '',
  forceRefresh: false,
};

const ItemsFilter: React.FC<FilterInputsProps> = ({
  currentState,
  categories,
  onSearch,
  onTableTabChange = () => {},
  summaries = [],
  parentFilter,
  totalItemCount,
  factory,
  shouldFetch = false,
  tabIndex,
  onImport,
  excludeImport = false,
  addButton,
}) => {
  const exportSetting = getExportSetting(factory);
  const [filter, setFilter] = useState({
    ...defaultFilter,
    ...parentFilter,
    ...(categories && { categoryId: 0 }),
  });
  const SEARCH_INPUT_WIDTH = 80;
  const ACCESS = getAccess(currentState);

  const searchNow = (e) => {
    if (!shouldFetch) return;
    e.stopPropagation();
    onSearch(filter);
  };

  const searchNow2 = (value) => {
    onSearch({ ...filter, query: value });
  };

  const debouncedSearch = useCallback(debounce(searchNow2, 500), []); // eslint-disable-line react-hooks/exhaustive-deps

  const onQueryChange = (e) => {
    const newFilter = { ...filter, query: e.target.value };
    setFilter(newFilter);
    if (factory?.settings?.productSize > 200) return;
    if (!shouldFetch) onSearch(newFilter);
    else debouncedSearch(e.target.value);
  };

  const onChangeCategory = (e) => {
    const newFilter = { ...filter, categoryId: e.target.value };
    setFilter(newFilter);
    onSearch(newFilter);
  };

  const renderMenuItem = (cat) => {
    const { id, level, name } = cat;
    return (
      <MenuItem key={id} value={id} style={{ marginLeft: `${(level + 1) * 10}px` }}>
        {name}
      </MenuItem>
    );
  };

  const itemSizes = categories ? [4, 4, 4] : [6, 0, 4];
  const sampleFile = factory?.type === FACTORY_TYPE.ECOMMERCE ? SampleItemsLs : SampleItemsBakery;

  return (
    <>
      <FilterWrapper justifyContent="space-between" maxWidth="1200px">
        <Row width="100%">
          <Col gridSize={excludeImport ? 12 : 9} marginTop={SPACE.LARGE}>
            <Row>
              <Col gridSize={itemSizes[0]}>
                <IconInputWrapper>
                  <TextField
                    label=""
                    variant="outlined"
                    placeholder={intl('INPUT.PLACEHOLDER.SEARCH')}
                    size="small"
                    style={{ minWidth: SEARCH_INPUT_WIDTH + 'px', width: '100%' }}
                    value={filter.query}
                    onChange={onQueryChange}
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        ev.preventDefault();
                        searchNow(ev);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={searchNow}>
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </IconInputWrapper>
              </Col>
              {categories && (
                <Col gridSize={itemSizes[1]}>
                  <FormControl size="small" style={{ width: '100%' }} variant="outlined">
                    <InputLabel id="group-select">Category</InputLabel>
                    <Select
                      id="group-select"
                      label="Category"
                      onChange={onChangeCategory}
                      value={filter.categoryId}>
                      <MenuItem value={0}>All Categories</MenuItem>
                      {categories.map((c) => renderMenuItem(c))}
                    </Select>
                  </FormControl>
                </Col>
              )}
              <Col gridSize={itemSizes[2]}>
                <Div>{addButton ? addButton : null}</Div>
              </Col>
            </Row>
          </Col>
          {!excludeImport && (
            <Col gridSize={3} marginTop={SPACE.LARGE}>
              <Flex className="center">
                <Div marginRight={SPACE.MEDIUM} className={accessDisable(ACCESS.ACTION.ITEM.IMPORT)}>
                  <Button
                    startIcon={<CloudUpload />}
                    className="outlined secondary filled"
                    onClick={() => onImport && onImport()}>
                    {intl('BUTTON.IMPORT')}
                  </Button>
                </Div>
                <ExportButton
                  filter={filter}
                  fileName="items"
                  exportSetting={exportSetting}
                  className={accessDisable(ACCESS.ACTION.ITEM.IMPORT)}
                />
              </Flex>
              <Div className="center" padding={SPACE.MEDIUM}>
                <ExportButton
                  component={<Link>{intl('MESSAGE.DOWNLOAD_SAMPLE_CSV')}</Link>}
                  filter={filter}
                  data={sampleFile}
                  fileName="sample_items"
                  exportSetting={exportSetting}
                />
              </Div>
            </Col>
          )}
        </Row>
      </FilterWrapper>
      {summaries.length > 0 && (
        <SummarySection>
          {summaries.map((summary, i) => (
            <SummaryIcon
              key={i}
              className={tabIndex === i && 'active'}
              onClick={() => onTableTabChange(i)}
              icon={<summary.icon />}
              title={summary.title}
              subtitle={summary.subtitle}
            />
          ))}
        </SummarySection>
      )}
    </>
  );
};

export default ItemsFilter;
