import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import Header from '$components/header';

import { IonPageWrapper } from './styles';
import { factoryActions } from '$fbusiness/redux/factory';
import { TableView2 } from '$gcomponents/widgets';
import { DISCOUNT_TABLE, DISCOUNT_TABLE_CONFIG } from './discountTable';
import DiscountModel, { initialDiscount } from '$fbusiness/models/discount';
import DiscountEditModal from './discountEditModal';
import FabButton from '$fcomponents/fabButton';
import ItemActionModel from '$gbusiness/models/itemAction';
import { accessHide, getAccess, isAccessible } from '$fbusiness/helpers/util';
import { dialog } from '$gcomponents/reusables';

interface DiscountsScreenProps {
  onHydrate;
  currentState;
  discounts: Array<DiscountModel>;
  deleteDiscount;
}

const DiscountsScreen: React.FC<DiscountsScreenProps> = ({ discounts, deleteDiscount, currentState }) => {
  const [discount, setDiscount] = useState<any>(null);
  const ACCESS = getAccess(currentState);

  const onAddDiscount = () => {
    setDiscount(initialDiscount);
  };

  const discountItemActions: Array<ItemActionModel> = [
    {
      label: 'BUTTON.EDIT',
      eventName: 'editItem',
      disabled: () => !isAccessible(ACCESS.ACTION.SETTING.DISCOUNT.EDIT, currentState),
      onClick: setDiscount,
    },
    {
      label: 'BUTTON.DELETE',
      eventName: 'deleteItem',
      disabled: () => !isAccessible(ACCESS.ACTION.SETTING.DISCOUNT.DELETE, currentState),
      onClick: (row) => {
        dialog.confirm({
          message: 'MESSAGE.DELETE_WARNING',
          onPress: async () => {
            await deleteDiscount(row.id);
          },
        });
      },
    },
  ];

  return (
    <IonPageWrapper>
      <Header title="SCREEN.DISCOUNTS.TITLE" />
      <IonContent>
        <TableView2
          data={discounts}
          tableConfig={DISCOUNT_TABLE_CONFIG}
          TABLE={DISCOUNT_TABLE()}
          itemActions={discountItemActions}
        />
      </IonContent>
      <FabButton onClick={onAddDiscount} className={accessHide(ACCESS.ACTION.SETTING.DISCOUNT.CREATE)} />
      <DiscountEditModal
        show={!!discount}
        currentState={currentState}
        discount={discount}
        onClose={() => setDiscount(null)}
      />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  discounts: state.factory.factory.discounts,
});

const mapDispatchToProps = {
  onHydrate: () => factoryActions.fetchDiscounts(),
  updateDiscount: factoryActions.saveDiscount,
  deleteDiscount: factoryActions.deleteDiscount,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(DiscountsScreen));
