import React, { useEffect, useState } from 'react';
import intl from '$intl';
import { TableView2 } from '$gcomponents/widgets';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { initialCredit } from '$fbusiness/models/credit';
import StoreModel from '$fbusiness/models/store';
import { currency } from '$gbusiness/helpers/util';

import CreditModal from './creditModal';
import { creditTableEditable, CREDIT_TABLE_CONFIG, CREDIT_TABLE } from './creditTable';
import CurrentStateModel from '$fbusiness/models/currentState';
import { useHistory } from 'react-router';
import { accessHide } from '$fbusiness/helpers/util';
import { getAccess } from '$fbusiness/helpers/util';

interface CreditsListProps {
  store: StoreModel;
  currentState?: CurrentStateModel;
  editable?: boolean;
}

const CreditsList: React.FC<CreditsListProps> = ({ store, editable = false, currentState }) => {
  const { id: storeId } = store;
  const isFactory = !!currentState?.isFactory;
  const ACCESS = getAccess(currentState);
  const history = useHistory();
  const [filter, setFilter] = useState({
    all: true,
    excludePayment: true,
    forceRefresh: true,
    query: '',
    void: true,
    storeId,
  });
  const [credit, setCredit] = useState<any>(null);

  const updateFilter = (newFilter = {}) => {
    setFilter({
      ...filter,
      ...newFilter,
      forceRefresh: !filter.forceRefresh,
    });
  };

  const addCredit = () => {
    history.push(`/create-credit/0/${storeId}`);
  };

  const addCustomCredit = () => {
    setCredit({
      ...initialCredit,
      storeId,
    });
  };

  const onSaveCredit = () => {
    setCredit(null);
    updateFilter();
  };

  const onEditPayment = (row, i) => {
    if (row.simple) {
      setCredit(row);
      return;
    }
    history.push('/edit-refund/' + row.id);
  };

  useEffect(() => {
    setFilter({ ...filter, storeId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId]);

  const creditTable = isFactory ? creditTableEditable(onEditPayment) : CREDIT_TABLE;

  return (
    <>
      <Flex justifyContent="space-between">
        <h2>{intl('SCREEN.CREDIT.TITLE')}</h2>
        <div>
          {currentState?.isFactory && (
            <Button
              onClick={addCredit}
              variant="outlined"
              className={accessHide(ACCESS.ACTION.CREDIT.CREATE)}>
              {intl('ITEM.ADD', { item: 'Credit' })}
            </Button>
          )}
          {currentState?.settings?.customCredit && (
            <Button
              style={{ marginLeft: '10px' }}
              onClick={addCustomCredit}
              variant="outlined"
              className={accessHide(ACCESS.ACTION.CREDIT.CREATE)}>
              {intl('ITEM.ADD', { item: 'Custom Credit' })}
            </Button>
          )}
        </div>
      </Flex>
      <div className="balance">
        {intl('SCREEN.CREDIT.BALANCE')}: <span className="price">{currency(store.credits || 0)}</span>
      </div>
      <TableView2 filter={filter} TABLE={creditTable} tableConfig={CREDIT_TABLE_CONFIG(store.id)} />
      <CreditModal
        show={!!credit}
        credit={credit}
        store={store}
        onClose={() => setCredit(null)}
        onSaved={onSaveCredit}
      />
    </>
  );
};

export default CreditsList;
