import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$fcomponents/hoc';
import { Header as FactoryHeader, TableView2 } from '$gcomponents/widgets';
import StoreHeader from '../../../components/header';

import { IonPageWrapper } from './styles';
import FabButton from '$fcomponents/fabButton';
import PATH from '$fbusiness/enums/paths';
import { MESSAGE_TABLE, MESSAGE_TABLE_CONFIG } from './messageTable';
import { Formik } from 'formik';
import { FormSection } from '$gcomponents/reusables';
import THREAD_FILTER_FORM from './threadFilterForm';
import { Button } from '$gcomponents/primitives';
import intl from '$gintl';
import { threadActions } from '$fbusiness/redux/thread';
import { DATE_RANGE } from '$fbusiness/enums/options/dateRange';
import { datetimeToStringUtc } from '$gbusiness/helpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import { accessHide, getAccess } from '$fbusiness/helpers/util';
import { generateSelectOptions } from '$gcomponents/utils/input';
import { insertEmptyOption } from '$gbusiness/helpers/input';

interface MessagesScreenProps {
  history;
  factory;
  stores;
  onHydrate;
  onDehydrate;
  currentState;
  updateThread;
}

const MessagesScreen: React.FC<MessagesScreenProps> = React.memo(
  ({ history, factory, currentState, stores, updateThread }) => {
    const formRef = useRef<any>();
    const [filter, setFilter] = useState<any>({});
    const { isFactory } = currentState;
    const ACCESS = getAccess(currentState);

    const initialValues = {
      content: '',
      title: '',
      contactName: '',
      ticketNumber: '',
      storeId: 0,
      status: '',
      datePeriod: DATE_RANGE.ALL_TIME,
    };

    const factoryUsers = insertEmptyOption(
      (factory?.users || []).map((t) => ({
        labelText: t.name,
        value: t.userId,
      })),
      'None',
    );
    const factoryUserOptions = generateSelectOptions(factoryUsers, true);

    const onClickRow = (row) => {
      history.push(`${PATH.MESSAGE}/${row.id}`);
    };

    const Header = currentState.isFactory ? (
      <FactoryHeader title="SCREEN.MESSAGES.TITLE" />
    ) : (
      <StoreHeader title="SCREEN.MESSAGES.TITLE" />
    );

    const onChangeStatus = (thread, status) => {
      updateThread(thread.id, { status }).then(updateFilter);
    };
    const onChangeStatusFilter = ({ value }) => {
      updateFilter({ status: value });
    };
    const onChangeStore = ({ value }) => {
      updateFilter({ storeId: value ? parseInt(value.id) : 0 });
    };
    const onChangeAssignee = (thread, userId) => {
      updateThread(thread.id, { assignedTo: userId }).then(updateFilter);
    };
    const updateFilter = (newFilter = {}) => {
      const formValues = formRef.current.values;
      const { store, dateStart, dateEnd, ...rest } = formValues;
      const dateFilter = {
        dateStart: datetimeToStringUtc(dateStart, DATE_FORMATS.DATE),
        dateEnd: datetimeToStringUtc(dateEnd, DATE_FORMATS.DATE),
      };
      setFilter({
        ...filter,
        ...dateFilter,
        ...rest,
        ...newFilter,
        forceRefresh: !filter.forceRefresh,
      });
    };

    const filterForm = THREAD_FILTER_FORM(isFactory, stores, onChangeStore, onChangeStatusFilter);

    const onSubmit = (values) => {
      updateFilter({});
    };

    return (
      <IonPageWrapper>
        {Header}
        <IonContent>
          <Formik
            innerRef={formRef}
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values) => {
              onSubmit(values);
            }}>
            {(formik) => (
              <TableView2
                filter={filter}
                tableConfig={MESSAGE_TABLE_CONFIG(onClickRow, isFactory)}
                TABLE={MESSAGE_TABLE({
                  onChangeStatus,
                  isFactory,
                  factoryUsers: factoryUserOptions,
                  onChangeAssignee,
                })}>
                <FilterSection>
                  <FormSection
                    className="serial-filter"
                    lastColumn={
                      <Button
                        color="secondary"
                        className="filter-submit"
                        size="small"
                        onClick={formik.handleSubmit}>
                        {intl('BUTTON.SUBMIT')}
                      </Button>
                    }
                    FORM={filterForm}
                    formik={formik}
                  />
                </FilterSection>
              </TableView2>
            )}
          </Formik>
        </IonContent>
        <FabButton route={PATH.NEW_MESSAGE} className={accessHide(ACCESS.ACTION.SUPPORT.CREATE)} />
      </IonPageWrapper>
    );
  },
  (pp, np) => true,
);

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  resetOnRoute: true,
  stores: state.store.stores,
});

const mapDispatchToProps = {
  updateThread: threadActions.updateThread,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(MessagesScreen));
