import React from 'react';
import FactoryModel from '$fbusiness/models/factory';
import { IonFabButton, IonFabList } from '@ionic/react';
import { Button } from '$gcomponents/primitives';
import { Add } from '@mui/icons-material';
import StoreModel from '$fbusiness/models/store';
import PATH from '$business/enums/paths';
import { useHistory } from 'react-router';
import { itemText } from '$gbusiness/helpers/util';
import { IonFabWrapper } from './styles';
import { dialog } from '$gcomponents/reusables';
import intl from '$gintl';
import { COLORS } from '$gbusiness/enums';
import { accessHide, getAccess, isAccessible } from '$fbusiness/helpers/util';

interface CreateNewFabsProps {
  currentState;
  factory: FactoryModel;
  store?: StoreModel;
}

const CreateNewFabs: React.FC<CreateNewFabsProps> = ({ currentState, factory, store }) => {
  const history = useHistory();
  const { settings } = factory;
  const ACCESS = getAccess(currentState);

  const canCreateInvoice = isAccessible(ACCESS.ACTION.INVOICE.CREATE, currentState);
  const canCreateRefund = isAccessible(ACCESS.ACTION.REFUND.CREATE, currentState);
  const canCreateCredit = isAccessible(ACCESS.ACTION.CREDIT.CREATE, currentState);
  const canDoNothing = !canCreateCredit && !canCreateInvoice && !canCreateRefund;

  const onNewRefund = () => {
    if (!isAccessible(ACCESS.ACTION.CREDIT.CREATE, currentState)) {
      navRefund(false);
      return;
    }
    dialog.confirm({
      message: 'MESSAGE.CREDIT_OR_REFUND',
      buttons: [
        {
          text: intl('COMMON.REFUND'),
          cssClass: COLORS.SUCCESS,
          handler: navRefund,
        },
        {
          text: intl('COMMON.CREDIT'),
          cssClass: COLORS.WARNING,
          handler: () => navRefund(true),
        },
      ],
    });
  };

  const navRefund = (isCredit) => {
    const path = isCredit ? PATH.CREATE_CREDIT : PATH.CREATE_REFUND;
    if (store?.id) {
      history.push(`${path}/0/${store.id}`);
      return;
    }
    history.push(`${path}/0`);
  };

  const onNewInvoice = (invoiceId = 0) => {
    if (store?.id) {
      history.push(`${PATH.INVOICE}/0/${invoiceId || '0'}/${store?.id}`);
      return;
    }
    history.push(`${PATH.INVOICE}/0/${invoiceId || '0'}`);
  };

  const enableFab = settings?.createInvoice || settings?.refundVoucher;
  if (!enableFab) return null;

  return (
    <IonFabWrapper
      className={`add-fab no-print ${canDoNothing ? 'hide' : ''}`}
      vertical="top"
      horizontal="end"
      slot="fixed">
      <IonFabButton size="small">
        <Add />
      </IonFabButton>
      <IonFabList side="bottom">
        {factory?.settings?.refundVoucher && (
          <Button
            icon={<Add />}
            className={canCreateCredit || canCreateRefund ? '' : 'hide'}
            variant="outlined"
            onClick={onNewRefund}>
            {itemText(
              'CREATE_NEW',
              canCreateCredit && canCreateRefund ? 'REFUND_CREDIT' : canCreateCredit ? 'CREDIT' : 'REFUND',
            )}
          </Button>
        )}
        <Button
          icon={<Add />}
          className={accessHide(ACCESS.ACTION.INVOICE.CREATE)}
          variant="outlined"
          onClick={(e) => onNewInvoice()}>
          {itemText('CREATE_NEW', 'INVOICE')}
        </Button>
      </IonFabList>
    </IonFabWrapper>
  );
};

export default CreateNewFabs;
