import React from 'react';
import { IonButton, IonContent, IonFooter } from '@ionic/react';
import { Formik, Form } from 'formik';

import { Text } from '$gintl';
import userModel from '$gbusiness/models/user';
import { FormSection } from '$gcomponents/reusables';
import { input } from '$gbusiness/helpers';
import { Wrapper } from '$gstyles';

import { USER_FORM } from './userForm';
import Legend from '$gcomponents/utils/legend';
import FactoryModel from '$fbusiness/models/factory';
import { getAccess, isAccessible } from '$fbusiness/helpers/util';
import CurrentStateModel from '$fbusiness/models/currentState';

interface FormViewProps {
  user: userModel;
  isMe: boolean;
  factory: FactoryModel;
  onSubmit: Function;
  currentState: CurrentStateModel;
  roles: Array<any>;
}

const FormView: React.FC<FormViewProps> = ({ user, isMe, factory, roles, currentState, onSubmit }) => {
  if (!user) return null;

  const ACCESS = getAccess(currentState);

  const isStoreUser = user?.other?.isStoreUser;
  const userForm = USER_FORM(isMe, roles, factory, isStoreUser);

  const initialValues = input.deriveFormInitialValues(userForm, user);
  const { settings } = user;

  const validateForm = (values) => {
    return input.validateError(userForm, values);
  };

  const canSave = user?.userId
    ? isAccessible(isStoreUser ? ACCESS.ACTION.STORE_USER.EDIT : ACCESS.ACTION.USER.EDIT, currentState)
    : isAccessible(ACCESS.ACTION.USER.CREATE, currentState);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initialValues, settings, roleId: user?.level?.id?.toString() }}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(user?.userId, values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <Wrapper maxWidth="800px">
              <Form>
                <FormSection FORM={userForm} formik={formik} />
              </Form>
              <Legend
                title="INPUT.LEGEND.USER_LEVELS"
                width="440px"
                scale={1}
                legends={['ADMIN', 'MANAGER', 'EMPLOYEE', 'ASSISTANT']}
              />
            </Wrapper>
          </IonContent>
          <IonFooter>
            <IonButton
              onClick={() => {
                formik.handleSubmit();
              }}
              disabled={!formik.isValid || !formik.dirty || !canSave}
              expand="block">
              <Text k="SCREEN.USER.SAVE_BUTTON" />
            </IonButton>
          </IonFooter>
        </>
      )}
    </Formik>
  );
};

export default FormView;
