import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { IonContent } from '@ionic/react';
import { IconButton, Button as MuiButton } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';

import intl from '$intl';
import { screen } from '$fcomponents/hoc';
import { Div, SPACE } from '$gstyles';
import { input } from '$gbusiness/helpers';
import { dialog, FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import { Flex } from '$gstyles/wrapper';
import Header from '$components/header';
import { factoryActions } from '$fbusiness/redux/factory';
import FactoryModel from '$fbusiness/models/factory';
import { AppForm } from '$styles/general';
import { currency, itemText, percentage } from '$gbusiness/helpers/util';
import { initialTax } from '$fbusiness/models/tax';

import { IonPageWrapper } from './styles';
import FACTORY_FORM from '../factoryDetailsScreen/factoryForm';
import TaxModalScreen from '../taxModalScreen';
import { initialShipping } from '$fbusiness/models/shipping';
import ShippingModalScreen from '../shippingModalScreen';
import { TAX_TYPE } from '$fbusiness/enums/options/taxType';
import { INVOICE_NUMBER } from '$fbusiness/enums/options/invoiceNumber';
import { initActions } from '$business/redux/init';
import { initialSimple } from '$gbusiness/models/simple';
import ShippingMethodModal from '../shippingMethodModal';
import PopOver from '$gcomponents/reusables/popOver';
import { MoreVert } from '@mui/icons-material';
import { configs } from '$configs';
import ChargeModal from '../chargeModal';
import { accessDisable, accessHide, getAccess } from '$fbusiness/helpers/util';
import CurrentStateModel from '$fbusiness/models/currentState';

interface FactorySettingScreenProps {
  history: any;
  factory: FactoryModel | null;
  saveFactory: Function;
  fetchMyFactory: Function;
  deleteTax: Function;
  deleteVendor: Function;
  deleteShipping: Function;
  applyTax: Function;
  deleteObj: Function;
  currentState: CurrentStateModel;
  isFinished: boolean;
}

const FactorySettingScreen: React.FC<FactorySettingScreenProps> = ({
  factory,
  saveFactory,
  fetchMyFactory,
  deleteTax,
  currentState,
  applyTax,
  deleteShipping,
  deleteObj,
}) => {
  const formRef = useRef<any>();
  const [tax, setTax] = useState<any>(null);
  const [shipping, setShipping] = useState<any>(null);
  const [shippingMethod, setShippingMethod] = useState<any>(null);
  const [charge, setCharge] = useState<any>(null);

  if (!factory) return <IonPageWrapper />;
  const {
    name,
    code,
    phone,
    email,
    formatted,
    taxes,
    shippings,
    shippingMethods,
    charges,
    settings,
    factoryId,
  } = factory;
  const initialValues = {
    name,
    code,
    phone,
    email,
    formatted,
    settings: {
      ...settings,
      expireDays2: settings?.expireDays2 || 60,
      expireDays: settings?.expireDays || 30,
      ...(!settings.invoiceStyle && {
        invoiceStyle: INVOICE_NUMBER.DEFAULT,
        invoiceSuffix: '',
        invoiceStarting: 100000,
      }),
    },
  };
  const ACCESS = getAccess(currentState);

  const onSubmit = async (values) => {
    const { address, formatted, taxes, ...newValues } = values;
    await saveFactory(factoryId, {
      ...newValues,
      address: formatted,
    });
    fetchMyFactory();
  };

  const factoryForm = FACTORY_FORM(settings, null, false);

  const validateForm = (values) => {
    const generalValidation = input.validateError(factoryForm, values);
    return {
      ...generalValidation,
    };
  };

  const onApplyTax = (taxId, unapply = false) => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: unapply ? 'MESSAGE.UNAPPLY_TAX_WARNING' : 'MESSAGE.APPLY_TAX_WARNING',
      onPress: async () => {
        applyTax(taxId, unapply).then(() => {
          fetchMyFactory();
        });
      },
    });
  };

  const deleteShippingMethod = (id) => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.DELETE_WARNING',
      onPress: async () => {
        deleteObj(id, configs.api.shippingMethod, factoryActions.fetchShippingMethods);
      },
    });
  };
  const deleteCharge = (id) => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.DELETE_WARNING',
      onPress: async () => {
        deleteObj(id, configs.api.charge, factoryActions.fetchCharges);
      },
    });
  };

  return (
    <IonPageWrapper>
      <Header titleText={intl('SCREEN.FACTORY.SETTING_TITLE')} />
      <IonContent>
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={initialValues}
          validate={validateForm}
          onSubmit={(values) => {
            onSubmit(values);
          }}>
          {(formik) => (
            <AppForm>
              <Div maxWidth="800px" className="container">
                <FormSection
                  FORM={factoryForm}
                  formik={formik}
                  title="SCREEN.FACTORY.FACTORY_INFO"
                  marginBottom={SPACE.LARGE}
                  className="form-section"
                  afterRender={
                    <Flex justifyContent="flex-end">
                      <Button
                        disabled={!formik.isValid || !formik.dirty}
                        color="primary"
                        className={`submit-button ${accessDisable(ACCESS.ACTION.SETTING.GENERAL.EDIT)}`}
                        variant="contained"
                        onClick={formik.handleSubmit}>
                        {intl('BUTTON.SUBMIT')}
                      </Button>
                    </Flex>
                  }
                />
              </Div>

              <Div maxWidth="500px" className="container">
                <FormSection
                  className="form-section"
                  title="SCREEN.FACTORY.TAX_TITLE"
                  FORM={[]}
                  marginBottom="0"
                  titleButton={
                    <Button
                      startIcon={<AddIcon />}
                      size="small"
                      className={accessHide(ACCESS.ACTION.SETTING.TAX.CREATE)}
                      color="secondary"
                      onClick={() => setTax(initialTax)}>
                      {itemText('add', 'tax')}
                    </Button>
                  }
                  formik={null}>
                  <Div className="tax-section">
                    {taxes?.map((tax, i) => {
                      return (
                        <Flex key={i}>
                          <Flex>
                            <div className="item-name">{tax.name}</div>
                            <div className="item-description">
                              {intl('INPUT.OPTION.TAX_TYPE.' + tax.taxType)}
                              {tax.taxType === TAX_TYPE.UNIVERSAL && ' (' + percentage(tax.amount) + ')'}
                            </div>
                            {/* <div className="item-description">
                              ({tax.unit === 'PERCENT' ? percentage(tax.amount) : currency(tax.amount)})
                            </div> */}
                          </Flex>
                          <Flex justifyContent="space-between">
                            <Flex>
                              <IconButton
                                onClick={() => setTax(tax)}
                                className={accessHide(ACCESS.ACTION.SETTING.TAX.EDIT)}>
                                <EditOutlinedIcon />
                              </IconButton>
                              {i > 0 && (
                                <IconButton
                                  onClick={() => deleteTax(tax.id)}
                                  className={accessHide(ACCESS.ACTION.SETTING.TAX.DELETE)}>
                                  <DeleteOutlinedIcon />
                                </IconButton>
                              )}
                            </Flex>
                            <PopOver
                              component={
                                <MuiButton className="left ellipsis" fullWidth>
                                  <MoreVert color="secondary" className="pointer" />
                                </MuiButton>
                              }>
                              <>
                                <Button
                                  variant="text"
                                  className={`left ${accessDisable(ACCESS.ACTION.SETTING.TAX.APPLY_ALL)}`}
                                  onClick={() => onApplyTax(tax.id)}>
                                  {intl('BUTTON.APPLY_TAX')}
                                </Button>
                                <Button
                                  variant="text"
                                  className={`left ${accessDisable(ACCESS.ACTION.SETTING.TAX.UNAPPLY_ALL)}`}
                                  onClick={() => onApplyTax(tax.id, true)}>
                                  {intl('BUTTON.UNAPPLY_TAX')}
                                </Button>
                              </>
                            </PopOver>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Div>
                </FormSection>
              </Div>

              <Div maxWidth="500px" className="container">
                <FormSection
                  className="form-section"
                  title="SCREEN.FACTORY.SHIPPING_TITLE"
                  FORM={[]}
                  marginBottom="0"
                  titleButton={
                    <Button
                      startIcon={<AddIcon />}
                      size="small"
                      color="secondary"
                      className={accessHide(ACCESS.ACTION.SETTING.SHIPPING.CREATE)}
                      onClick={() => setShipping(initialShipping)}>
                      {itemText('add', 'shipping')}
                    </Button>
                  }
                  formik={null}>
                  <Div className="tax-section">
                    {shippings?.map((shipping, i) => {
                      return (
                        <Flex key={i}>
                          <Flex>
                            <div className="item-name">{shipping.name}</div>
                            <div className="item-description">({currency(shipping.deliveryCost)})</div>
                            <div className="item-description">{shipping.days} business days</div>
                          </Flex>
                          <Flex>
                            <IconButton
                              onClick={() => setShipping(shipping)}
                              className={accessHide(ACCESS.ACTION.SETTING.SHIPPING.EDIT)}>
                              <EditOutlinedIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => deleteShipping(shipping.id)}
                              className={accessHide(ACCESS.ACTION.SETTING.SHIPPING.EDIT)}>
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Div>
                </FormSection>
              </Div>

              <Div maxWidth="500px" className="container">
                <FormSection
                  className="form-section"
                  title="SCREEN.FACTORY.SHIPPING_METHOD_TITLE"
                  FORM={[]}
                  marginBottom="0"
                  titleButton={
                    <Button
                      startIcon={<AddIcon />}
                      size="small"
                      color="secondary"
                      className={accessHide(ACCESS.ACTION.SETTING.SHIPPING_METHOD.CREATE)}
                      onClick={() => setShippingMethod(initialSimple)}>
                      {itemText('add', 'shipping method')}
                    </Button>
                  }
                  formik={null}>
                  <Div className="tax-section">
                    {shippingMethods?.map((shipping, i) => {
                      return (
                        <Flex key={i}>
                          <Flex>
                            <div className="item-name">{shipping.name}</div>
                          </Flex>
                          <Flex>
                            <IconButton
                              onClick={() => setShippingMethod(shipping)}
                              className={accessHide(ACCESS.ACTION.SETTING.SHIPPING_METHOD.EDIT)}>
                              <EditOutlinedIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => deleteShippingMethod(shipping.id)}
                              className={accessHide(ACCESS.ACTION.SETTING.SHIPPING_METHOD.DELETE)}>
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Div>
                </FormSection>
              </Div>

              <Div maxWidth="500px" className="container">
                <FormSection
                  className="form-section"
                  title="SCREEN.FACTORY.CHARGE_TITLE"
                  FORM={[]}
                  marginBottom="0"
                  titleButton={
                    <Button
                      startIcon={<AddIcon />}
                      size="small"
                      color="secondary"
                      className={accessHide(ACCESS.ACTION.SETTING.CHARGE.CREATE)}
                      onClick={() => setCharge(initialSimple)}>
                      {itemText('add', 'charge option')}
                    </Button>
                  }
                  formik={null}>
                  <Div className="tax-section">
                    {charges?.map((s, i) => {
                      return (
                        <Flex key={i}>
                          <Flex>
                            <div className="item-name">{s.name}</div>
                          </Flex>
                          <Flex>
                            <IconButton
                              onClick={() => setCharge(s)}
                              className={accessHide(ACCESS.ACTION.SETTING.CHARGE.EDIT)}>
                              <EditOutlinedIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => deleteCharge(s.id)}
                              className={accessHide(ACCESS.ACTION.SETTING.CHARGE.DELETE)}>
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Div>
                </FormSection>
              </Div>
            </AppForm>
          )}
        </Formik>
        <Div padding={SPACE.XXLARGE} />
        <TaxModalScreen tax={tax} onClose={() => setTax(null)} />
        <ShippingModalScreen shipping={shipping} onClose={() => setShipping(null)} />
        <ShippingMethodModal shippingMethod={shippingMethod} onClose={() => setShippingMethod(null)} />
        <ChargeModal charge={charge} onClose={() => setCharge(null)} />
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: () => factoryActions.fetchMyFactory(),
  saveFactory: factoryActions.saveFactory,
  applyTax: factoryActions.applyTax,
  deleteTax: factoryActions.deleteTax,
  fetchMyFactory: () => initActions.fetchMyFactory(true),
  deleteShipping: factoryActions.deleteShipping,
  deleteObj: factoryActions.deleteObj,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(FactorySettingScreen));
