import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Delete } from '@mui/icons-material';

import { screen } from '$fcomponents/hoc';
import { dialog } from '$gcomponents/reusables';
import { Header, TableView2 } from '$gcomponents/widgets';
import ItemActionModel from '$gbusiness/models/itemAction';

import FILE_TABLE, { FILE_TABLE_CONFIG } from './table';
import { IonPageWrapper } from './styles';
import FileModal from './fileModal';
import { fileActions } from '$fbusiness/redux/file';
import FileEditModal from './fileModal/edit';
import StoreModel from '$fbusiness/models/store';
import FabButton from '$fcomponents/fabButton';
import { FormControl, InputLabel, Select } from '@mui/material';
import { generateSelectOptions } from '$gcomponents/utils/input';
import FILE_TYPE_OPTIONS, { FILE_TYPES } from '$fbusiness/enums/options/fileType';
import { PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import intl from '$gintl';
import { Col, Row } from '$gstyles';
import { accessHide, getAccess, isAccessible } from '$fbusiness/helpers/util';

interface FilesScreenProps {
  fetchFile: Function;
  deleteFile: Function;
  cleanFiles: Function;
  stores: Array<StoreModel>;
  currentState;
}

const FilesScreen: React.FC<FilesScreenProps> = ({ stores, cleanFiles, deleteFile, currentState }) => {
  const [filter, setFilter] = useState<any>({ query: '', forceRefresh: true, category: FILE_TYPES.FILE });
  const [showModal, setShowModal] = useState(false);
  const [selections, setSelections] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const ACCESS = getAccess(currentState);

  const canDelete = isAccessible(ACCESS.ACTION.FILE.DELETE, currentState);

  const onCloseModal = () => {
    setShowModal(false);
    setFile(null);
  };
  const onCloseEditModal = () => {
    setFile(null);
  };
  const onUpdateFile = () => {
    updateFilter({});
    onCloseModal();
  };

  const updateFilter = (newFilter) => {
    setFilter({
      ...filter,
      ...newFilter,
      forceRefresh: !filter.forceRefresh,
    });
  };

  const onSelection = (files) => {
    if (files.length) setSelections(files.filter((f) => f).map((f) => f.id));
    else setSelections(null);
  };

  const onDeleteFiles = () => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.DELETE_WARNING',
      onPress: async () => {
        for (const id of selections) {
          await deleteFile(id);
        }
        updateFilter({ resetSelection: true });
        setSelections(null);
      },
    });
  };

  const onCleanFiles = () => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.FILE_CLEAN_WARNING',
      onPress: async () => {
        await cleanFiles();
        updateFilter({});
      },
    });
  };

  const itemActions: Array<ItemActionModel> = [
    {
      label: 'BUTTON.DELETE',
      eventName: 'deleteItem',
      disabled: () => !canDelete,
      onClick: (row) => {
        dialog.confirm({
          message: 'MESSAGE.DELETE_WARNING',
          onPress: async () => {
            await deleteFile(row.id);
            updateFilter({});
          },
        });
      },
    },
    {
      label: 'BUTTON.EDIT',
      eventName: 'edit',
      disabled: () => !isAccessible(ACCESS.ACTION.FILE.EDIT, currentState),
      onClick: async (row) => {
        setFile(row);
      },
    },
  ];

  return (
    <IonPageWrapper>
      <Header title="SCREEN.FILES.TITLE" />
      <PageWrapper>
        <Row>
          <Col gridSize={6}>
            <FormControl size="small" variant="outlined">
              <InputLabel id="cat-select">File Category</InputLabel>
              <Select
                size="small"
                id="cat-select"
                className="small cat-filter"
                variant="outlined"
                label="File Category"
                value={filter.category}
                onChange={(e) => updateFilter({ category: e.target.value })}>
                {generateSelectOptions(FILE_TYPE_OPTIONS, true)}
              </Select>
            </FormControl>
          </Col>
          <Col gridSize={6}>
            <div className="pull-right">
              <Button
                onClick={onCleanFiles}
                size="small"
                className={`pull-right ${accessHide(ACCESS.ACTION.FILE.CLEAN)}`}
                color="secondary">
                {intl('SCREEN.FILES.BUTTON_CLEAN')}
              </Button>
              {/* <Button onClick={onCleanFiles} size="small" className="pull-right" color="error">
                {intl('SCREEN.FILES.BUTTON_PURGE')}
              </Button> */}
            </div>
          </Col>
        </Row>
      </PageWrapper>

      <TableView2
        onSelection={canDelete ? onSelection : undefined}
        tableConfig={FILE_TABLE_CONFIG}
        TABLE={FILE_TABLE(filter.category)}
        filter={filter}
        itemActions={itemActions}
      />
      <FileModal show={showModal} onClose={onCloseModal} onUpdateFile={onUpdateFile} stores={stores} />
      <FileEditModal
        show={!!file}
        onClose={onCloseEditModal}
        file={file}
        stores={stores}
        onUpdateFile={onUpdateFile}
      />
      {filter.category === FILE_TYPES.FILE && (
        <FabButton className={accessHide(ACCESS.ACTION.FILE.CREATE)} onClick={() => setShowModal(true)} />
      )}
      {selections && (
        <FabButton
          ver="bottom"
          className={accessHide(ACCESS.ACTION.FILE.DELETE)}
          icon={<Delete />}
          color="danger"
          onClick={onDeleteFiles}></FabButton>
      )}
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  stores: state.store.stores,
  factory: state.factory.factory,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  fetchFile: fileActions.fetchFile,
  deleteFile: fileActions.deleteFile,
  cleanFiles: fileActions.cleanupFiles,
  updateFile: fileActions.updateFile,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(FilesScreen));
