import React from 'react';

import { SORT } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { IconButton, Tooltip } from '@mui/material';
import {
  MoreHoriz,
  Star,
  StarOutline,
  BookmarkOutlined,
  BookmarkBorderOutlined,
  VisibilityOutlined,
  VisibilityOff,
} from '@mui/icons-material';
import { timestamp } from '$gbusiness/helpers/date';
import intl from '$intl';
import Img from '$gcomponents/primitives/img';
import { Link } from '$gcomponents/primitives';
import { FACTORY_TYPE } from '$fbusiness/enums/options/factoryType';
import { TAX_TYPE } from '$fbusiness/enums/options/taxType';
import Breadcrumbs from '$fcomponents/breadcrumbs';
import { percentage } from '$gbusiness/helpers/util';
import PATH from '$business/enums/paths';
import { accessHide, getAccess } from '$fbusiness/helpers/util';

export const defaultItemColumns = ['sku', 'categoryName', 'price', 'wholesalePrice', 'tax', 'quantity'];

export const PRODUCTS_TABLE = (
  factory,
  onToggleHide,
  onToggleEbrochure,
  onTogglePromo,
  currentState,
): Array<CellModel> => {
  const ACCESS = getAccess(currentState);
  return [
    {
      label: 'SCREEN.ITEMS.COLS.PHOTO',
      value: 'itemId',
      className: 'image',
      align: 'center',
      component: (row) => {
        const thumbImage = row.image?.sizes?.small;
        return (
          row.image && (
            <Img
              src={thumbImage || ''}
              cache={timestamp(row.updatedAt)}
              width="64px"
              height="64px"
              placeholder="No Image"
              alt="dept img"
            />
          )
        );
      },
      width: 150,
    },
    {
      label: 'SCREEN.ITEMS.COLS.SKU',
      value: 'sku',
      className: 'desktop',
      sortable: SORT.ASC,
      width: 160,
    },
    {
      label: 'SCREEN.ITEMS.COLS.BARCODE',
      value: 'barcode',
      className: 'desktop',
      sortable: SORT.ASC,
      width: 160,
    },
    {
      label: 'SCREEN.ITEMS.COLS.CATEGORY',
      value: 'categoryName',
      className: 'vertical',
      align: 'left',
      component: (row) => {
        if (factory.settings?.subcategory && row.categories && row.categories.length) {
          const category = row.categories[0];
          return (
            <Breadcrumbs
              noLink
              separator="›"
              excludeHome
              breadcrumbs={category.breadcrumbs}
              current={category}
            />
          );
        }
        return row?.categories && row.categories.map((d) => <div key={d.id}>{d.name}</div>);
      },
      sortable: SORT.ASC,
      width: 200,
    },
    {
      label: 'SCREEN.ITEMS.COLS.NAME',
      value: 'name',
      className: 'main',
      component: (row) => <Link route={PATH.ITEMS + '/' + row.id}>{row.name}</Link>,
      alwaysShow: true,
      sortable: SORT.ASC,
      width: 250,
    },
    {
      label: 'SCREEN.ITEMS.COLS.RETAIL_PRICE',
      value: 'price',
      align: 'center',
      component: (row) => <>{intl('PRICE', { amount: row.retailPrice })}</>,
      sortable: SORT.ASC,
      width: 120,
    },
    {
      label: 'SCREEN.ITEMS.COLS.PRICE',
      value: 'wholesalePrice',
      align: 'center',
      component: (row) => <>{intl('PRICE', { amount: row.wholesalePrice })}</>,
      sortable: SORT.ASC,
      width: 120,
    },
    {
      label: 'SCREEN.ITEMS.COLS.RAW_COST',
      value: 'rawCost',
      align: 'center',
      component: (row) => <>{intl('PRICE', { amount: row.rawCost })}</>,
      sortable: SORT.ASC,
      width: 120,
    },
    {
      label: 'SCREEN.ITEMS.COLS.TAX',
      value: 'tax',
      align: 'center',
      component: (row) => (
        <>
          {!row.taxId
            ? 'No Tax'
            : row.tax?.taxType === TAX_TYPE.UNIVERSAL
            ? percentage(row.tax?.amount || 0)
            : TAX_TYPE.STORE}
        </>
      ),
      width: 120,
    },
    {
      label: 'SCREEN.ITEMS.COLS.QUANTITY',
      value: 'quantity',
      align: 'center',
      className: 'desktop',
      component: (row) => <>{row.quantity == null ? 'Unlimited' : row.quantity}</>,
      sortable: SORT.ASC,
      width: 120,
    },
    {
      isHidden: factory.type !== FACTORY_TYPE.ECOMMERCE,
      label: 'SCREEN.ITEMS.COLS.PACKING',
      value: 'settings.packing',
      align: 'center',
      className: 'desktop',
      sortable: SORT.ASC,
      width: 120,
    },
    {
      isHidden: factory.type !== FACTORY_TYPE.ECOMMERCE,
      label: 'SCREEN.ITEMS.COLS.SKID',
      value: 'settings.skid',
      align: 'center',
      className: 'desktop',
      sortable: SORT.ASC,
      width: 120,
    },
    {
      isHidden: factory.type !== FACTORY_TYPE.ECOMMERCE,
      label: 'SCREEN.ITEMS.COLS.LOTNO',
      value: 'settings.lotNo',
      align: 'center',
      className: 'desktop',
      sortable: SORT.ASC,
      width: 120,
    },
    {
      isHidden: factory.type !== FACTORY_TYPE.ECOMMERCE,
      label: 'SCREEN.ITEMS.COLS.BINNO',
      value: 'settings.binNo',
      align: 'center',
      className: 'desktop',
      sortable: SORT.ASC,
      width: 120,
    },
    {
      label: 'SCREEN.ITEMS.COLS.ACTION',
      value: '',
      className: 'action',
      align: 'center',
      alwaysShow: true,
      component: (row, actions) => {
        return (
          <div className="item-actions">
            <Tooltip
              title={intl('SCREEN.ITEM.TOOLTIP.EBROCHURE')}
              className={accessHide(ACCESS.ACTION.ITEM.EDIT)}>
              <IconButton size="small" onClick={() => onToggleEbrochure(row)}>
                {row.ebrochure ? (
                  <BookmarkOutlined color="primary" />
                ) : (
                  <BookmarkBorderOutlined color="disabled" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={intl('SCREEN.ITEM.TOOLTIP.PROMO')}
              className={accessHide(ACCESS.ACTION.ITEM.EDIT)}>
              <IconButton size="small" onClick={() => onTogglePromo(row)}>
                {row.settings.promo ? <Star color="primary" /> : <StarOutline color="disabled" />}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={intl('SCREEN.ITEM.TOOLTIP.HIDDEN')}
              className={accessHide(ACCESS.ACTION.ITEM.EDIT)}>
              <IconButton size="small" onClick={() => onToggleHide(row)}>
                {!row.hide ? <VisibilityOutlined color="disabled" /> : <VisibilityOff color="error" />}
              </IconButton>
            </Tooltip>
            <IconButton
              size="small"
              onClick={(e) => actions.handlePopoverOpen(e, { ...row, route: PATH.ITEMS })}>
              <MoreHoriz />
            </IconButton>
          </div>
        );
      },
      width: 150,
      minWidth: 150,
    },
  ];
};
