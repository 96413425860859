import { ROUTES } from '$gbusiness/enums';
import GPATH from '$gbusiness/enums/paths';
import FPATH from '$fbusiness/enums/paths';
import RouteModel from '$gbusiness/models/route';
import PATH from './paths';

import {
  Home,
  Person,
  Receipt,
  Restaurant,
  Business,
  SettingsOutlined,
  Assignment,
  Group,
  AccountTree,
  FolderOpen,
  Notifications,
  AssessmentOutlined,
  AttachMoney,
  AssignmentReturn,
  History,
  GetApp,
  QrCode,
  AutoDelete,
  Email,
  // Today,
} from '@mui/icons-material';

import {
  HomeScreen,
  ProfileScreen,
  InventoryScreen,
  FactoriesScreen,
  FactoryDetailsScreen,
  FactorySettingScreen,
  StoresScreen,
  StoreDetailsScreen,
  DepartmentDetailsScreen,
  UserScreen,
  UsersScreen,
  CategoryDetailsScreen,
  ItemDetailsScreen,
  CategoriesScreen,
  OrderScreen,
  InvoicesScreen,
  EditInvoiceScreen,
  FilesScreen,
  LoginScreen,
  RegisterScreen,
  NotificationsScreen,
  StoreReportScreen,
  StoreReport2Screen,
  VendorsScreen,
  DiscountsScreen,
  CommRulesScreen,
  SalesmenScreen,
  RefundsScreen,
  EditRefundScreen,
  RegionsScreen,
  TermsScreen,
  RepReportScreen,
  TermReportScreen,
  ShippingReportScreen,
  ItemReportScreen,
  ItemsReportScreen,
  BalanceReportScreen,
  CommissionsScreen,
  CommInvoicesScreen,
  RegionReportScreen,
  SalesSummaryScreen,
  CompanySnapshotScreen,
  TransactionsScreen,
  BatchesScreen,
  SerialsScreen,
  MockScreen,
  LabelsScreen,
  InvoiceTypesScreen,
} from '$product/screens';
import { getSideMenu } from '$gbusiness/helpers/util';
import { SideMenuModel } from '$gbusiness/models/sideMenu';
import TosScreen from '../../../factory/screens/tos';
import DailyReportsScreen from '../../../factory/screens/dailyReportScreens/dailyReportsScreen';
import MessageScreen from '../../../factory/screens/messageScreens/messageScreen';
import MessagesScreen from '../../../factory/screens/messageScreens/messagesScreen';
import NewMessageScreen from '../../../factory/screens/messageScreens/newMessageScreen';
import ArReportScreen from '../../../factory/screens/accountScreen/arReportScreen';
import BroadcastsScreen from '$fscreens/accountScreen/broadcastsScreen';

export { PATH };

export const TOS_ROUTE = {
  path: PATH.TOS,
  component: TosScreen,
};

export const HOME_ROUTE = {
  title: 'MENU.HOME',
  path: PATH.HOME,
  icon: Home,
  component: HomeScreen,
};

export const MOCK_ROUTE = {
  path: PATH.MOCK,
  component: MockScreen,
  accessLevel: 'SCREEN.MOCK',
};

export const PROFILE_ROUTE = {
  title: 'MENU.PROFILE',
  path: PATH.PROFILE,
  icon: Person,
  component: ProfileScreen,
};

export const FACTORIES_ROUTE = {
  title: 'MENU.FACTORIES',
  path: PATH.FACTORIES,
  icon: Business,
  accessLevel: 'SCREEN.FACTORIES',
  component: FactoriesScreen,
};

export const FACTORY_ROUTE = {
  path: PATH.FACTORIES,
  param: ':factoryId',
  defaultId: '0',
  accessLevel: 'SCREEN.FACTORY',
  component: FactoryDetailsScreen,
};

export const STORES_ROUTE = {
  title: 'MENU.STORES',
  path: PATH.STORES,
  accessLevel: 'SCREEN.STORES',
  icon: Business,
  component: StoresScreen,
};

export const STORE_ROUTE = {
  path: PATH.STORES,
  param: ':storeId',
  accessLevel: 'SCREEN.STORE',
  defaultId: '0',
  component: StoreDetailsScreen,
};

export const DEPARTMENT_ROUTE = {
  path: PATH.DEPARTMENTS,
  param: ':departmentId',
  defaultId: '0',
  accessLevel: 'SCREEN.DEPARTMENTS',
  component: DepartmentDetailsScreen,
};

export const CATEGORY_ROUTE = {
  path: PATH.CATEGORIES,
  param: ':categoryId',
  defaultId: '0',
  accessLevel: 'SCREEN.CATEGORY',
  component: CategoryDetailsScreen,
};

export const CATEGORIES_ROUTE = {
  title: 'MENU.CATEGORIES',
  path: PATH.MULTI_CATEGORIES,
  icon: AccountTree,
  feature: 'subcategory',
  accessLevel: 'SCREEN.CATEGORIES',
  component: CategoriesScreen,
};

export const ITEM_ROUTE = {
  path: PATH.ITEMS,
  param: ':itemId',
  defaultId: '0',
  accessLevel: 'SCREEN.ITEM',
  component: ItemDetailsScreen,
};

export const BATCH_ROUTE = {
  title: 'MENU.BATCH',
  path: PATH.BATCH,
  param: ':itemId?',
  feature: 'batch',
  icon: GetApp,
  accessLevel: 'SCREEN.RECEIVING',
  component: BatchesScreen,
};

export const EXPIRATION_ROUTE = {
  title: 'MENU.EXPIRATION',
  path: PATH.EXPIRATION,
  param: ':itemId?',
  feature: 'expiration',
  icon: AutoDelete,
  accessLevel: 'SCREEN.EXPIRATION',
  component: BatchesScreen,
};

export const SERIAL_ROUTE = {
  title: 'MENU.SERIAL',
  path: PATH.SERIAL,
  param: ':itemId?/:batchId?',
  feature: 'serial',
  icon: QrCode,
  accessLevel: 'SCREEN.SERIAL',
  component: SerialsScreen,
};

export const INVENTORY_ROUTE = {
  title: 'MENU.ITEMS',
  path: PATH.INVENTORY,
  param: ':tabIndex',
  defaultId: '0',
  icon: Restaurant,
  accessLevel: 'SCREEN.ITEMS',
  component: InventoryScreen,
};

export const ORDER_ROUTE = {
  title: 'MENU.ORDERS',
  path: PATH.ORDER,
  param: ':tabIndex',
  defaultId: '1',
  icon: Assignment,
  accessLevel: 'SCREEN.ORDERS',
  component: OrderScreen,
};

export const INVOICES_ROUTE = {
  title: 'MENU.INVOICES',
  param: ':storeId?/:tabIndex?',
  defaultId: '',
  path: PATH.INVOICES,
  icon: Receipt,
  accessLevel: 'SCREEN.INVOICES',
  feature: 'showInvoices',
  component: InvoicesScreen,
};

export const INVOICE_ROUTE = {
  path: PATH.INVOICE,
  defaultId: '0',
  param: ':isEdit/:invoiceId?/:storeId?',
  accessLevel: 'SCREEN.INVOICE',
  feature: 'createInvoice',
  component: EditInvoiceScreen,
};

export const CREATE_CREDIT_ROUTE = {
  path: PATH.CREATE_CREDIT,
  param: ':invoiceId?/:storeId?/:shouldConvert?',
  accessLevel: 'SCREEN.CREDIT',
  component: EditRefundScreen,
};

export const CREATE_REFUND_ROUTE = {
  path: PATH.CREATE_REFUND,
  param: ':invoiceId?/:storeId?/:shouldConvert?',
  accessLevel: 'SCREEN.REFUND',
  feature: 'refundVoucher',
  component: EditRefundScreen,
};

export const EDIT_REFUND_ROUTE = {
  path: PATH.EDIT_REFUND,
  param: ':refundId?',
  accessLevel: 'SCREEN.REFUND',
  feature: 'refundVoucher',
  component: EditRefundScreen,
};

export const REFUNDS_ROUTE = {
  title: 'MENU.REFUNDS',
  path: PATH.REFUNDS,
  param: ':processed?',
  defaultId: '',
  icon: AssignmentReturn,
  accessLevel: 'SCREEN.REFUNDS',
  feature: 'refundVoucher',
  component: RefundsScreen,
};

export const COMMISSIONS_ROUTE = {
  title: 'MENU.COMMISSIONS',
  path: PATH.COMMISSIONS,
  icon: AttachMoney,
  param: ':period?',
  default: '0',
  accessLevel: 'SCREEN.COMMISSIONS',
  feature: 'commission',
  component: CommissionsScreen,
};

export const COMMINVOICES_ROUTE = {
  path: PATH.COMMINVOICES,
  defaultId: '0',
  param: ':periodId/:userId?/:period?',
  accessLevel: 'SCREEN.COMMISSIONS',
  feature: 'commission',
  component: CommInvoicesScreen,
};

export const COMMREFUNDS_ROUTE = {
  ...COMMINVOICES_ROUTE,
  path: PATH.COMMREFUNDS,
};

export const COMMCREDITS_ROUTE = {
  ...COMMINVOICES_ROUTE,
  path: PATH.COMMCREDITS,
};

// export const TRANSACTIONS_ROUTE = {
//   title: 'MENU.TRANSACTIONS',
//   path: PATH.TRANSACTIONS,
//   defaultId: '0',
//   param: ':storeId?',
//   accessLevel: 1,
//   icon: AccountBalance,
//   feature: 'showTransactions',
//   component: TransactionsScreen,
// };

export const BALANCE_DETAILS_ROUTE = {
  title: 'MENU.BALANCE_DETAILS',
  path: PATH.BALANCE_DETAILS,
  defaultId: '0',
  param: ':storeId?',
  accessLevel: 'SCREEN.BALANCE_DETAILS',
  icon: History,
  feature: 'showTransactions',
  component: TransactionsScreen,
};

export const USERS_ROUTE = {
  title: 'MENU.USERS',
  path: GPATH.USERS,
  icon: Group,
  component: UsersScreen,
  accessLevel: 'SCREEN.USERS',
  exact: true,
};

export const USER_ROUTE = {
  path: GPATH.USERS,
  param: ':userId',
  defaultId: '0',
  component: UserScreen,
  accessLevel: 'SCREEN.USER',
  exact: true,
};

export const FILES_ROUTE = {
  title: 'MENU.FILES',
  path: PATH.FILES,
  icon: FolderOpen,
  component: FilesScreen,
  accessLevel: 'SCREEN.FILES',
  feature: 'file',
  exact: true,
};

export const NOTIFICATION_ROUTE = {
  title: 'MENU.NOTIFICATION',
  path: PATH.NOTIFICATION,
  component: NotificationsScreen,
  accessLevel: 'SCREEN.EMAIL_NOTIFICATION',
  feature: 'notification',
  exact: true,
};

export const LOGIN_ROUTE = {
  path: GPATH.LOGIN,
  component: LoginScreen,
  exact: true,
};

export const REGISTER_ROUTE = {
  path: GPATH.REGISTER,
  component: RegisterScreen,
  exact: true,
};

export const SETTINGS_ROUTE = {
  title: 'MENU.SETTINGS_GENERAL',
  accessLevel: 'SCREEN.SETTING.GENERAL',
  path: PATH.SETTINGS,
  component: FactorySettingScreen,
};

export const LABELS_ROUTE = {
  title: 'MENU.SETTINGS_LABELS',
  accessLevel: 'SCREEN.SETTING.LABELS',
  path: PATH.LABELS,
  component: LabelsScreen,
};

export const REGIONS_ROUTE = {
  title: 'MENU.REGIONS',
  accessLevel: 'SCREEN.SETTING.REGIONS',
  path: PATH.REGIONS,
  feature: 'regions',
  component: RegionsScreen,
};

export const VENDORS_ROUTE = {
  title: 'MENU.SETTINGS_VENDORS',
  accessLevel: 'SCREEN.SETTING.VENDORS',
  path: PATH.VENDORS,
  feature: 'showVendor',
  component: VendorsScreen,
};

export const DISCOUNTS_ROUTE = {
  title: 'MENU.SETTINGS_DISCOUNTS',
  accessLevel: 'SCREEN.SETTING.DISCOUNTS',
  path: PATH.DISCOUNTS,
  feature: 'discounts',
  component: DiscountsScreen,
};

export const COMMRULES_ROUTE = {
  title: 'MENU.SETTINGS_COMMRULES',
  accessLevel: 'SCREEN.SETTING.COMMRULES',
  path: PATH.COMMRULES,
  feature: 'commission',
  component: CommRulesScreen,
};

export const TERMS_ROUTE = {
  title: 'MENU.SETTINGS_TERMS',
  path: PATH.TERMS,
  component: TermsScreen,
  accessLevel: 'SCREEN.SETTING.TERMS',
  feature: 'terms',
  exact: true,
};

export const INVOICE_TYPES_ROUTE = {
  title: 'MENU.SETTINGS_INVOICE_TYPES',
  path: PATH.INVOICE_TYPES,
  component: InvoiceTypesScreen,
  accessLevel: 'SCREEN.SETTING.INVOICE_TYPES',
  feature: 'invoice',
  exact: true,
};

export const SALESMEN_ROUTE = {
  title: 'MENU.SETTINGS_SALESMEN',
  accessLevel: 'SCREEN.SETTING.SALESMEN',
  path: PATH.SALESMEN,
  feature: 'commission',
  component: SalesmenScreen,
};

export const SETTING_GROUP = {
  title: 'MENU.SETTINGS',
  icon: SettingsOutlined,
  routes: [
    SETTINGS_ROUTE,
    REGIONS_ROUTE,
    VENDORS_ROUTE,
    DISCOUNTS_ROUTE,
    SALESMEN_ROUTE,
    COMMRULES_ROUTE,
    TERMS_ROUTE,
    INVOICE_TYPES_ROUTE,
    LABELS_ROUTE,
  ],
};

export const BROADCAST_ROUTE: RouteModel = {
  title: 'MENU.NOTIFICATION',
  path: FPATH.BROADCASTS,
  icon: Notifications,
  component: BroadcastsScreen,
  accessLevel: 'SCREEN.EMAIL_NOTIFICATION',
  feature: 'notification',
};

export const DAILY_REPORT_ROUTE: RouteModel = {
  title: 'MENU.DAILY_REPORT',
  path: PATH.DAILY_REPORT,
  component: DailyReportsScreen,
  accessLevel: 'SCREEN.REPORT.DAILY',
  feature: 'dailyReport',
};

export const MESSAGE_ROUTE: RouteModel = {
  path: PATH.MESSAGE,
  param: ':threadId?',
  defaultId: '',
  feature: 'messaging',
  accessLevel: 'SCREEN.MESSAGE',
  component: MessageScreen,
};

export const MESSAGES_ROUTE: RouteModel = {
  path: PATH.MESSAGES,
  title: 'MENU.MESSAGES',
  subtitle: 'SCREEN.MESSAGES.TITLE',
  icon: Email,
  feature: 'messaging',
  accessLevel: 'SCREEN.MESSAGES',
  component: MessagesScreen,
};

export const MESSAGE_ID_ROUTE: RouteModel = {
  path: PATH.MESSAGES_ID,
  param: ':messageId?',
  defaultId: '',
  feature: 'messaging',
  accessLevel: 'SCREEN.MESSAGE',
  component: MessageScreen,
};

export const NEW_MESSAGE_ROUTE: RouteModel = {
  path: PATH.NEW_MESSAGE,
  feature: 'messaging',
  accessLevel: 'SCREEN.MESSAGE',
  component: NewMessageScreen,
};

export const KONGDOG_ROUTE = {
  title: 'MENU.KONGDOG',
  path: PATH.KONGDOG,
  component: NotificationsScreen,
  accessLevel: 'SCREEN.KONGDOG',
  feature: 'kongdog',
  exact: true,
};

export const STORE_REPORT_ROUTE = {
  title: 'MENU.REPORT_STORE',
  path: PATH.REPORT_STORE,
  param: ':regionId?/:userId?/:itemId?',
  defaultId: '',
  component: StoreReportScreen,
  accessLevel: 'SCREEN.REPORT.STORE',
  exact: true,
};

export const FILTER_REPORT_ROUTE = {
  title: 'MENU.REPORT_FILTER',
  path: PATH.REPORT_FILTER,
  component: StoreReport2Screen,
  accessLevel: 'SCREEN.REPORT.FILTER',
  exact: true,
};

export const AR_REPORT_ROUTE = {
  title: 'MENU.REPORT_AR',
  path: PATH.REPORT_AR,
  component: ArReportScreen,
  accessLevel: 'SCREEN.REPORT.AR',
};

export const REFUND_ITEM_REPORT_ROUTE = {
  title: 'MENU.REPORT_REFUND_ITEM',
  path: PATH.REPORT_REFUND_ITEM,
  component: ItemReportScreen,
  param: ':refund',
  defaultId: '1',
  accessLevel: 'SCREEN.REPORT.REFUND',
  exact: true,
};

export const ITEM_REPORT_ROUTE = {
  title: 'MENU.REPORT_ITEM',
  path: PATH.REPORT_ITEM,
  component: ItemReportScreen,
  param: ':top?/:no?',
  defaultId: '',
  accessLevel: 'SCREEN.REPORT.ITEM',
  exact: true,
};

export const TOP_REPORT_ROUTE = {
  title: 'MENU.REPORT_TOP',
  path: PATH.REPORT_TOP,
  component: ItemReportScreen,
  param: ':top?/:no?',
  defaultId: 'top/20',
  accessLevel: 'SCREEN.REPORT.TOP',
  exact: true,
};

export const BOTTOM_REPORT_ROUTE = {
  title: 'MENU.REPORT_BOTTOM',
  path: PATH.REPORT_BOTTOM,
  component: ItemReportScreen,
  param: ':top?/:no?',
  defaultId: 'bottom/20',
  accessLevel: 'SCREEN.REPORT.BOTTOM',
  exact: true,
};

export const REP_REPORT_ROUTE = {
  title: 'MENU.REPORT_REP',
  path: PATH.REPORT_REP,
  component: RepReportScreen,
  accessLevel: 'SCREEN.REPORT.REP',
  feature: 'commission',
  exact: true,
};

export const TERM_REPORT_ROUTE = {
  title: 'MENU.REPORT_TERM',
  path: PATH.REPORT_TERM,
  component: TermReportScreen,
  accessLevel: 'SCREEN.REPORT.TERM',
  feature: 'terms',
  exact: true,
};

export const SHIPPING_REPORT_ROUTE = {
  title: 'MENU.REPORT_SHIPPING',
  path: PATH.REPORT_SHIPPING,
  component: ShippingReportScreen,
  accessLevel: 'SCREEN.REPORT.SHIPPING',
  exact: true,
};

export const REGION_REPORT_ROUTE = {
  title: 'MENU.REPORT_REGION',
  path: PATH.REPORT_REGION,
  component: RegionReportScreen,
  feature: 'regions',
  accessLevel: 'SCREEN.REPORT.REGION',
  exact: true,
};

export const SALES_SUMMARY_ROUTE = {
  title: 'MENU.REPORT_SUMMARY',
  path: PATH.REPORT_SUMMARY,
  component: SalesSummaryScreen,
  accessLevel: 'SCREEN.REPORT.SALES',
  exact: true,
};

export const COMPANY_SNAPSHOT_ROUTE = {
  title: 'MENU.REPORT_SNAPSHOT',
  path: PATH.REPORT_SNAPSHOT,
  component: CompanySnapshotScreen,
  accessLevel: 'SCREEN.REPORT.SNAPSHOT',
  exact: true,
};

export const BALANCE_REPORT_ROUTE = {
  title: 'MENU.REPORT_BALANCE',
  path: PATH.REPORT_BALANCE,
  component: BalanceReportScreen,
  accessLevel: 'SCREEN.REPORT.BALANCE',
  exact: true,
};

export const ITEM_DETAILS_REPORT_ROUTE = {
  title: 'MENU.TITLE_ITEM_DETAILS',
  path: PATH.REPORT_ITEM_DETAILS,
  param: ':userId/:storeId/:itemId',
  defaultParam: '0',
  component: ItemsReportScreen,
  accessLevel: 'SCREEN.REPORT.ITEM_DETAILS',
  exact: true,
};

export const ITEM_DETAILS_REPORT2_ROUTE = {
  title: 'MENU.TITLE_ITEM_DETAILS',
  path: PATH.REPORT_ITEM_DETAILS2,
  param: ':regionId/:termId/:shippingMethodId/',
  defaultParam: '0',
  component: ItemsReportScreen,
  accessLevel: 'SCREEN.REPORT.ITEM_DETAILS2',
  exact: true,
};

export const REFUND_DETAILS_REPORT_ROUTE = {
  title: 'MENU.TITLE_ITEM_DETAILS',
  path: PATH.REPORT_REFUND_DETAILS,
  param: ':refund/:userId/:storeId/:itemId',
  defaultParam: '0/0/0/0',
  component: ItemsReportScreen,
  accessLevel: 'SCREEN.REPORT.REFUND_DETAILS',
  exact: true,
};

export const REPORTS_GROUP = {
  title: 'MENU.REPORT',
  icon: AssessmentOutlined,
  routes: [
    COMPANY_SNAPSHOT_ROUTE,
    SALES_SUMMARY_ROUTE,
    FILTER_REPORT_ROUTE,
    AR_REPORT_ROUTE,
    // BALANCE_REPORT_ROUTE,
    STORE_REPORT_ROUTE,
    REP_REPORT_ROUTE,
    ITEM_REPORT_ROUTE,
    REFUND_ITEM_REPORT_ROUTE,
    TERM_REPORT_ROUTE,
    SHIPPING_REPORT_ROUTE,
    REGION_REPORT_ROUTE,
    TOP_REPORT_ROUTE,
    BOTTOM_REPORT_ROUTE,
    DAILY_REPORT_ROUTE,
    KONGDOG_ROUTE,
  ],
};

export const LIST: Array<RouteModel> = [
  // HOME_ROUTE,
  CATEGORIES_ROUTE,
  INVENTORY_ROUTE,
  ORDER_ROUTE,
  INVOICES_ROUTE,
  INVOICE_ROUTE,
  REFUNDS_ROUTE,
  CREATE_REFUND_ROUTE,
  CREATE_CREDIT_ROUTE,
  EDIT_REFUND_ROUTE,
  REGIONS_ROUTE,
  LABELS_ROUTE,
  COMMISSIONS_ROUTE,
  COMMINVOICES_ROUTE,
  COMMREFUNDS_ROUTE,
  COMMCREDITS_ROUTE,
  DAILY_REPORT_ROUTE,
  NEW_MESSAGE_ROUTE,
  MESSAGES_ROUTE,
  MESSAGE_ROUTE,
  MESSAGE_ID_ROUTE,
  STORES_ROUTE,
  STORE_ROUTE,
  USERS_ROUTE,
  USER_ROUTE,
  SETTINGS_ROUTE,
  TERMS_ROUTE,
  VENDORS_ROUTE,
  INVOICE_TYPES_ROUTE,
  FACTORIES_ROUTE,
  FILES_ROUTE,
  AR_REPORT_ROUTE,
  STORE_REPORT_ROUTE,
  FILTER_REPORT_ROUTE,
  REP_REPORT_ROUTE,
  TERM_REPORT_ROUTE,
  SHIPPING_REPORT_ROUTE,
  REGION_REPORT_ROUTE,
  SALES_SUMMARY_ROUTE,
  COMPANY_SNAPSHOT_ROUTE,
  TOP_REPORT_ROUTE,
  BOTTOM_REPORT_ROUTE,
  ITEM_REPORT_ROUTE,
  REFUND_ITEM_REPORT_ROUTE,
  BALANCE_REPORT_ROUTE,
  ITEM_DETAILS_REPORT_ROUTE,
  ITEM_DETAILS_REPORT2_ROUTE,
  REFUND_DETAILS_REPORT_ROUTE,
  // TRANSACTIONS_ROUTE,
  BALANCE_DETAILS_ROUTE,
  KONGDOG_ROUTE,
  NOTIFICATION_ROUTE,
  FACTORY_ROUTE,
  DEPARTMENT_ROUTE,
  CATEGORY_ROUTE,
  ITEM_ROUTE,
  PROFILE_ROUTE,
  DISCOUNTS_ROUTE,
  COMMRULES_ROUTE,
  SALESMEN_ROUTE,
  BATCH_ROUTE,
  EXPIRATION_ROUTE,
  SERIAL_ROUTE,
  BROADCAST_ROUTE,
  MOCK_ROUTE,
];

export const SIDE_MENU: Array<SideMenuModel> = [
  getSideMenu(MESSAGES_ROUTE),
  getSideMenu(CATEGORIES_ROUTE),
  getSideMenu(INVENTORY_ROUTE),
  getSideMenu(BATCH_ROUTE),
  getSideMenu(EXPIRATION_ROUTE),
  getSideMenu(SERIAL_ROUTE),
  getSideMenu(ORDER_ROUTE),
  getSideMenu(INVOICES_ROUTE),
  getSideMenu(BALANCE_DETAILS_ROUTE),
  // getSideMenu(TRANSACTIONS_ROUTE),
  getSideMenu(STORES_ROUTE),
  getSideMenu(COMMISSIONS_ROUTE),
  getSideMenu(REFUNDS_ROUTE),
  getSideMenu(USERS_ROUTE),
  getSideMenu(FACTORIES_ROUTE),
  getSideMenu(BROADCAST_ROUTE),
  getSideMenu(FILES_ROUTE),
  getSideMenu(REPORTS_GROUP),
  getSideMenu(SETTING_GROUP),
  getSideMenu(PROFILE_ROUTE),
];

export const PUBLIC_LIST: Array<RouteModel> = [
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  ROUTES.FORGOT_ROUTE,
  ROUTES.RESET_PASSWORD_ROUTE,
  ROUTES.REGISTER_COMPLETE_ROUTE,
  ROUTES.VERIFY_EMAIL_ROUTE,
  ROUTES.VERIFY_TOKEN_ROUTE,
  TOS_ROUTE,
];

export const defaultRoute = ORDER_ROUTE;
export const DEFAULT_PUBLIC = LOGIN_ROUTE;
export const DEFAULT_PUBLIC_PATH = DEFAULT_PUBLIC.path;

export const DEFAULT_APP_PATH =
  defaultRoute.path + (defaultRoute.defaultId ? '/' + defaultRoute.defaultId : '');
