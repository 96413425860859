import { IonItem, IonIcon, IonLabel } from '@ionic/react';
import React from 'react';
import { removeCircleOutline, addCircleOutline } from 'ionicons/icons';
import { Button, Checkbox } from '@mui/material';
import { MoreHoriz, SubdirectoryArrowRight, VisibilityOff, VisibilityOutlined } from '@mui/icons-material';

import { COLORS } from '$gbusiness/enums';

import { WrapperItem } from './styles';
import PopOver from '$gcomponents/reusables/popOver';
import intl from '$gintl';
import { TreeItemModel } from '$fbusiness/models/tree';
import Img from '$gcomponents/primitives/img';
import { timestamp } from '$gbusiness/helpers/date';
import { SPACE } from '$gstyles';
import { accessDisable } from '$fbusiness/helpers/util';

interface TreeItemProps {
  treeItem: TreeItemModel;
  toggleExpand;
  addSubcategory;
  editCategory;
  removeCategory;
  ACCESS;
  isFilteredOut: boolean | null;
  isChecked?: boolean;
  isExpanded?: boolean;
  toggleCheck: Function;
  toggleHide: Function;
  level?: number;
}

const TreeItem: React.FC<TreeItemProps> = ({
  treeItem,
  toggleExpand,
  addSubcategory,
  editCategory,
  removeCategory,
  ACCESS,
  isFilteredOut = false,
  isExpanded = true,
  isChecked = false,
  toggleCheck,
  toggleHide,
  level = 0,
}) => {
  const { data, id, hasChildren } = treeItem;
  const { name, image, parentId } = data;

  return (
    <WrapperItem
      level={level}
      isFilteredOut={isFilteredOut}
      markBold={isFilteredOut === false && !isFilteredOut}>
      <IonItem>
        <Checkbox checked={isChecked} onChange={() => toggleCheck(id)} />
        <Img
          src={image?.sizes?.small || ''}
          className="category-image"
          cache={timestamp(image?.updatedAt)}
          height="36px"
          width="36px"
          margin={SPACE.SMALL}
          placeholder="No Image"
        />
        {level > 0 && <SubdirectoryArrowRight className="children-indicator" />}
        <IonIcon
          onClick={() => toggleExpand(id)}
          className={hasChildren ? 'link' : ''}
          color={hasChildren ? COLORS.SECONDARY : 'transparent'}
          icon={!isExpanded ? addCircleOutline : removeCircleOutline}
        />
        <IonLabel>{name}</IonLabel>
        {data.hide ? (
          <VisibilityOff onClick={() => toggleHide(id, false)} color="error" />
        ) : (
          <VisibilityOutlined onClick={() => toggleHide(id, true)} color="disabled" />
        )}
        <PopOver component={<MoreHoriz className="pointer" />}>
          <>
            <Button
              className={`left ${accessDisable(ACCESS.ACTION.CATEGORY.CREATE)}`}
              fullWidth
              onClick={() => addSubcategory(id)}>
              {intl('SCREEN.CATEGORIES.ACTIONS.ADD')}
            </Button>
            <Button
              className={`left ${accessDisable(ACCESS.ACTION.CATEGORY.EDIT)}`}
              fullWidth
              onClick={() => editCategory(id, parentId)}>
              {intl('SCREEN.CATEGORIES.ACTIONS.EDIT')}
            </Button>
            <Button
              className={`left ${accessDisable(ACCESS.ACTION.CATEGORY.DELETE)}`}
              fullWidth
              onClick={() => removeCategory(id)}>
              {intl('SCREEN.CATEGORIES.ACTIONS.REMOVE')}
            </Button>
          </>
        </PopOver>
      </IonItem>
    </WrapperItem>
  );
};

export default TreeItem;
