import React, { useState, useEffect } from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';

import { Div, FONT, H2, SPACE, WEIGHT } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import Footer from '$gcomponents/widgets/footer';

import { format } from '$gbusiness/helpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';
import FactoryModel from '$fbusiness/models/factory';

import InvoiceModel from '$fbusiness/models/invoice';
import OrderDetails from '$fcomponents/orderDetails';
import { ORDER_STATUS } from '$fbusiness/enums/options/orderStatus';
import { INVOICE_STATUS } from '$fbusiness/enums/options/invoiceStatus';
import { useDispatch } from 'react-redux';
import { invoiceActions } from '$fbusiness/redux/invoice';
import CurrentStateModel from '$fbusiness/models/currentState';
import { accessDisable, getAccess, getPaymentMethod } from '$fbusiness/helpers/util';
import { dialog } from '$gcomponents/reusables';
import { download, replaceAll } from '$gbusiness/helpers/util';
import { configs } from '$configs';

interface InvoiceDetailsProps {
  invoice: InvoiceModel;
  currentState: CurrentStateModel;
  factory: FactoryModel;
  itemId?: number;
  onRefresh?: Function;
  onClose: Function;
}

const MODES = {
  PACKING: 'packing-mode',
  INVOICE: 'invoice-mode',
  INITIAL: '',
};

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({
  currentState,
  factory,
  itemId,
  onRefresh = () => {},
  invoice: originalInvoice,
  onClose,
}) => {
  const [invoiceMode, setInvoiceMode] = useState(MODES.INITIAL);
  const [invoice, setInvoice] = useState(originalInvoice);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!invoiceMode) return;
    window.print();
  }, [invoiceMode]);

  const { invoiceNumber, orderId, order } = invoice;
  const { logo } = factory;
  const hasOrder = !!invoice.orderId;
  const ACCESS = getAccess(currentState);

  const onPrintInvoice = () => {
    if (invoiceMode === MODES.INVOICE) {
      window.print();
      return;
    }
    setInvoiceMode(MODES.INVOICE);
  };

  const onDeleteInvoiceCharge = (id) => {
    dialog.confirm({
      message: 'MESSAGE.DELETE_WARNING',
      onPress: async () => {
        await dispatch(invoiceActions.deleteInvoiceCharge(id)).then((inv) => {
          if (inv) setInvoice(inv);
          onRefresh();
        });
      },
    });
  };

  const onDownload = () => {
    const { id, uuid } = invoice;
    const url = `${process.env.REACT_APP_API_BASE}/${configs.api.invoice.download}/${uuid}`;
    download(url, 'invoice_' + id + '.pdf');
  };

  const onEmail = () => {
    const { id, store } = invoice;
    const invoiceEmail = store?.settings?.invoiceEmail || '';
    if (!invoiceEmail) {
      dialog.alert({
        title: 'MESSAGE.SORRY',
        message: 'MESSAGE.INVOICE_EMAIL_MISSING',
        key: { name: store?.name || '' },
      });
      return;
    }
    const trimmedEmail = replaceAll(invoiceEmail, { '\n': '', ' ': '' });
    dispatch(invoiceActions.emailInvoice(id, trimmedEmail));
  };

  const onPayInvoice = async () => {
    await dispatch(invoiceActions.updateInvoice({ id: invoice.id, status: INVOICE_STATUS.PAYMENT_SENT }));
    onClose(true);
  };

  const title = invoice.charge
    ? `${invoice.charge.name} ${invoice.invoiceNumber}`
    : intl('SCREEN.INVOICES.TITLE_PRINT', { invoiceNumber });

  return (
    <>
      <IonContent className={invoiceMode}>
        <Div padding={SPACE.XLARGE}>
          {logo && (
            <div className="logo invoice-print">
              <img src={logo} alt="logo" />
            </div>
          )}
          <H2 className="title" fontWeight={WEIGHT.BOLD}>
            <div className="invoice-print-block">
              {title}
              {!!invoice && (
                <div
                  className={`inv-status ${
                    invoice.status === INVOICE_STATUS.PAID || (invoice?.balance || 0) <= 0 ? 'paid' : ''
                  } ${invoice.status === INVOICE_STATUS.CANCELLED && 'void'}`}>
                  ({intl('INPUT.OPTION.INVOICE_STATUS.' + invoice.status)})
                </div>
              )}
            </div>
            {hasOrder && (
              <div className="packing-print">{intl('SCREEN.ORDER.TITLE_PACKING', { orderId })}</div>
            )}
            {invoice && (
              <div className="invoice-header">
                <div className="invoice-date">
                  {intl('SCREEN.INVOICES.COLS.CREATED')}: {format(invoice.createdAt)}
                </div>
                {invoice.paidAt && (
                  <div className="invoice-paid-date">
                    {intl('SCREEN.INVOICES.COLS.PAID_AT')}: {format(invoice.paidAt)}
                  </div>
                )}
                {invoice.payments.length > 0 && (
                  <div className="invoice-payment-method">
                    {intl('SCREEN.INVOICES.COLS.PAYMENT_METHOD')}: {getPaymentMethod(invoice.payments).join()}
                  </div>
                )}
              </div>
            )}
          </H2>
          {hasOrder && (
            <Div fontSize={FONT.MEDIUM} className="center">
              {intl('SCREEN.ORDER.DATE')}: {format(invoice?.order?.createdAt || '', DATE_FORMATS.SLASH)}
            </Div>
          )}

          <OrderDetails
            itemId={itemId}
            onDeleteInvoiceCharge={onDeleteInvoiceCharge}
            currentState={currentState}
            factory={factory}
            invoice={invoice}
            order={order}
          />
        </Div>
      </IonContent>
      <Footer justifyContent="space-around" className="no-print">
        <Button variant="outlined" className="" onClick={() => onClose()}>
          {intl('BUTTON.CANCEL')}
        </Button>
        <Button
          variant="contained"
          className={accessDisable(ACCESS.ACTION.INVOICE.DOWNLOAD)}
          onClick={onDownload}>
          {intl('BUTTON.DOWNLOAD')}
        </Button>
        {factory.settings?.invoiceEmail && (
          <Button
            variant="contained"
            className={accessDisable(ACCESS.ACTION.INVOICE.EMAIL)}
            onClick={onEmail}>
            {intl('BUTTON.SEND_EMAIL')}
          </Button>
        )}
        {order?.status === ORDER_STATUS.CLOSED && invoice.status === INVOICE_STATUS.OPEN ? (
          <Button className="" onClick={onPayInvoice}>
            {intl('BUTTON.PAYMENT_SENT')}
          </Button>
        ) : (
          <Button className="" onClick={onPrintInvoice}>
            {invoice.charge ? intl('BUTTON.PRINT') : intl('BUTTON.PRINT_INVOICE')}
          </Button>
        )}
      </Footer>
    </>
  );
};

export default InvoiceDetails;
