import React, { useEffect, useRef, useState } from 'react';
import intl from '$intl';
import { Col, Div, Row } from '$gstyles';
import { Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import Footer from '$gcomponents/widgets/footer';
import { dialog, FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import InvoiceModel, { deriveRawToInvoice } from '$fbusiness/models/invoice';
import StoreModel, { deriveRawToStore } from '$fbusiness/models/store';
import BankModel, { initialBank } from '$fbusiness/models/bank';
import {
  PAYMENT_CASH_FORM,
  PAYMENT_CHECK_FORM,
  PAYMENT_CREDIT_FORM,
  PAYMENT_FORM,
  PAYMENT_MEMO_FORM,
} from './paymentForm';
import { INVOICE_PAYMENTS, PAYMENT_TYPE } from '$fbusiness/enums/options/paymentType';
import BankInput from './bankInput';
import { useDispatch } from 'react-redux';
import { factoryActions } from '$fbusiness/redux/factory';
import { configs } from '$configs';
import PaymentSummary from '../paymentSummary';
import { Flex, PageWrapper } from '$gstyles/wrapper';
import { TableView2 } from '$gcomponents/widgets';
import {
  // INVOICE_PAYMENTS_TABLE_CONFIG,
  INVOICE_SIMPLE_TABLE,
  INVOICE_SIMPLE_TABLE_CONFIG,
} from './paymentsTable';
import { ToggleButtonGroup, ToggleButton, NativeSelect } from '@mui/material';
import { AccountBalance, Add, AttachMoney, CreditCard, Discount, LocalAtm } from '@mui/icons-material';
import { currency, sleep, toNumber } from '$gbusiness/helpers/util';
import {
  addOrSubstractNewPayment,
  adjustPaymentAmount,
  calculateConsumed,
  generateDiscountSelection,
  getSelectedInvoices,
  onChangeNewPaymentAmount,
  onChangePaymentForm,
  prepareOriginalInvoices,
  resetPayments,
} from './utils';
import { invoiceActions } from '$fbusiness/redux/invoice';
import { Link } from '$gstyles/general';
import { parseISO } from 'date-fns/esm';
import { getEl } from '$gbusiness/helpers/input';
import CreditHistoryModal from '$fcomponents/creditHistoryModal';
import { INVOICE_STATUS } from '$fbusiness/enums/options/invoiceStatus';
import { INNER_PAYMENTS_TABLE } from '$fscreens/accountScreen/invoicesScreen/innerTable';
import { TableModel } from '$gbusiness/models/table';
import MultiInvoiceSummary from '../multiInvoiceSummary';
import { dateToString } from '$gbusiness/helpers/date';
import { dispatchLoading, fetchApi, handleApiFail } from '$gbusiness/services/api';
import { INVOICE_FAILURE } from '$fbusiness/redux/invoice/types';
import { LOADED } from '$gbusiness/redux/loading/types';
import { DSC } from '$gbusiness/enums/sort';
import usePrevious from '$gbox/hooks/usePrevious';
import CurrentStateModel from '$fbusiness/models/currentState';
import CreditDropdown from './creditDropdown';
import InvoicePaymentGroupModel from '$fbusiness/models/invoicePaymentGroup';
import { DATE_FORMATS } from '$gbusiness/enums';
import { IonContentWrapper } from './styles';
import { accessDisable, getAccess, isAccessible } from '$fbusiness/helpers/util';

interface PaymentDetailsProps {
  currentState: CurrentStateModel;
  invoice?: InvoiceModel;
  store: StoreModel;
  banks: Array<BankModel>;
  paymentGroup?: InvoicePaymentGroupModel;
  onChangePaymentGroup: Function;
  stores: Array<StoreModel>;
  onRefreshInvoice: Function;
  touched: boolean;
  setTouched: Function;
  onClose: Function;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  currentState,
  invoice,
  banks,
  stores,
  paymentGroup,
  onRefreshInvoice,
  store: initialStore,
  onChangePaymentGroup,
  onClose,
  touched,
  setTouched,
}) => {
  const formRef = useRef<any>();
  const dispatch = useDispatch();
  const prevStoreId = usePrevious(0);
  const isEdit = !!paymentGroup?.id;

  // STATES
  const [paymentType, setPaymentType] = useState<any>(paymentGroup?.type || PAYMENT_TYPE.CASH);
  const [showCredits, setShowCredits] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState<Array<any>>([]);
  const [selectedPayment, setSelectedPayment] = useState<Array<any>>([]);
  const [showCreditDropdown, setShowCreditDropdown] = useState(false);
  const [invoiceStates, setInvoiceStatesPrimitive] = useState<any>({
    selectedIndexes: [],
    invoices: [],
    leftover: 0,
  });
  const [bank, setBank] = useState<any>(null);
  const [store, setStore] = useState<StoreModel>(initialStore);
  const [validPayment, setValidPayment] = useState<any>(!!paymentGroup);
  const [newCredit, setNewCredit] = useState(0);
  const [paymentConsumed, setPaymentConsumed] = useState(0);
  const ACCESS = getAccess(currentState);

  // VARIABLES
  const initialValues = {
    bankId: 0,
    fromName: store?.name,
    store: initialStore,
    paymentDate: new Date(),
    amount: '',
    creditAmount: '',
    ...(paymentGroup && {
      ...paymentGroup,
      paymentDate: parseISO(paymentGroup?.paymentDate || ''),
    }),
  };

  const { settings: factorySettings } = currentState;
  const { selectedIndexes, leftover, invoices } = invoiceStates;
  const numSelected = selectedIndexes.length;
  const isMultiInvoice = numSelected > 1;
  const selectedInvoice = numSelected === 1 ? invoices[selectedIndexes[0]] : null;
  const total = selectedInvoice?.totals || {};

  const isCredits = paymentType === INVOICE_PAYMENTS.CREDITS;
  const isDcMode = isCredits || paymentType === INVOICE_PAYMENTS.DISCOUNT;
  const paymentGroupMode = !!paymentGroup;
  const invoiceId = invoice?.id || 0;
  const { id: storeId, openBalance, credits } = store;
  const storeCredits = (credits || 0) - newCredit;
  const formValues = formRef?.current?.values || null;

  const creditDropdownMode = isCredits && factorySettings?.creditPaymentDropdown;
  const freezeCredit = creditDropdownMode && isCredits && leftover > 0 && paymentConsumed;
  const selectedInvoices = getSelectedInvoices(invoices, selectedIndexes);

  // HOOKS
  useEffect(() => {
    if (prevStoreId) resetForm(); // Don't reset form on very first load
    setSelectedIndexes([]);
    refreshInvoices();
    setNewCredit(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.id]);

  useEffect(() => {
    if (!formValues) return;
    formRef.current.validateForm(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCredits]);

  useEffect(() => {
    if (!invoices || !invoices.length) return;
    if (!paymentGroup) {
      refreshInvoices();
      setValidPayment(false);
      return;
    }
    const newForm = getFormValues(paymentGroup, false);
    formRef.current.setValues({ ...formRef.current.values, ...newForm });
    setPaymentType(newForm.type);
    setValidPayment(true);
    refreshInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentGroup?.id]);

  // METHODS
  const refreshInvoices = async (pg: any = paymentGroup) => {
    dispatchLoading(dispatch);
    const param: any = {
      storeId: store ? store.id.toString() : '',
      ...(pg
        ? { paymentGroupId: pg?.id }
        : {
            status: INVOICE_STATUS.OPEN,
            balance: '1',
            includeCharges: true,
          }),
      sortKey: 'created_at',
      sortOrder: DSC,
    };
    const response = await fetchApi({
      url: configs.api.invoice.general + '/?' + new URLSearchParams(param).toString(),
      method: 'GET',
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }
    dispatch({
      type: LOADED,
    });
    const newInvoices = prepareOriginalInvoices(
      response.list.map(deriveRawToInvoice),
      store,
      pg,
      generatePayment(),
    );
    setInvoiceStates(newInvoices);

    await sleep(100);
    scollToInvoice();
  };

  // const calculateTotals = (invs, paymentGroup) => {
  //   return invs.map((inv) => {
  //     const payments = inv.payments.filter((p) => p.paymentGroupId !== paymentGroup.id);
  //     return {
  //       ...inv,
  //       totals: calculateTotal(inv, store, payments, true, true),
  //     };
  //   });
  // };

  const scollToInvoice = async () => {
    if (!invoice) return;
    await sleep(300);
    const target = getEl('.payment-table [data-id="' + invoice.id + '"]');
    if (target) target.scrollIntoView({ behavior: 'smooth' });
  };

  const onChangeStore = (values, newValue) => {
    if (!newValue) return;
    setStore(newValue);
  };

  const setInvoiceStates = (state) => {
    setInvoiceStatesPrimitive({
      ...invoiceStates,
      ...(state.invoices && { invoices: state.invoices }),
      ...(state.leftover !== undefined && { leftover: state.leftover }),
      ...(state.selectedIndexes && { selectedIndexes: state.selectedIndexes }),
    });
  };

  const setSelectedIndexes = (arr, lo: any = null) => {
    setInvoiceStates({
      invoices: invoices,
      leftover: lo === 0 ? 0 : leftover || 0,
      setSelectedIndexes: arr,
    });
  };

  const getFormValues = (values, toDateString = true) => {
    const { settings, bankId, paymentDate: thisDate, memo, amount, creditAmount, type } = values;
    const isDateString = typeof thisDate === 'string';
    const formAmount = isCredits ? toNumber(creditAmount) : toNumber(amount);
    const paymentDate = toDateString
      ? isDateString
        ? thisDate
        : dateToString(thisDate, DATE_FORMATS.MYSQL)
      : isDateString
      ? parseISO(thisDate)
      : thisDate;
    return {
      ...(bankId && { bankId }),
      settings,
      type,
      memo: memo || '',
      paymentDate,
      originalAmount: toNumber(formAmount),
      amount,
    };
  };

  const generatePayment = (value = 0) => {
    if (!formRef.current?.values) return {};
    const values = formRef.current?.values;
    const { amount, creditAmount } = values;
    const formAmount = isCredits ? toNumber(creditAmount) : toNumber(amount);
    const paymentAmount = value || formAmount;

    return {
      id: values?.id || 0,
      dirty: true,
      ...(paymentGroup && { paymentGroupId: paymentGroup.id }),
      storeId,
      ...getFormValues(values),
      type: paymentType,
      originalAmount: paymentAmount,
      amount: paymentAmount,
    };
  };

  const onPaymentInputError = async (row, maxInput, message) => {
    await sleep(200);
    dialog.alert({
      title: 'MESSAGE.ALERT',
      message,
      onPress: async () => {
        const target = getEl('.payment-table [data-id="' + row.id + '"] input');
        await sleep(400);
        target.focus();
      },
    });
  };

  const onPressPayment = (value) => {
    setValidPayment(value ? true : false);
  };

  const resetInvoicePayments = (onConfirm = (e) => {}, onCancel = () => {}) => {
    dialog.confirm({
      message: 'MESSAGE.PAYMENT_CHANGE',
      onPress: async () => {
        const newInvoices = resetPayments(invoices, store);
        setInvoiceStates(newInvoices);
        onConfirm(newInvoices);
      },
      onPress2: () => {
        onCancel();
      },
    });
  };

  const onChangePaymentType = async (e, value) => {
    const proceed = async () => {
      setPaymentType(value);
      await sleep(100);
      formRef?.current.setTouched({});
    };
    if (freezeCredit) {
      alertFreezeCredit();
      return;
    }
    if (value === null) return;
    if (isCredits && selectedIndexes.length) {
      setInvoiceStates(resetPayments(invoices, store));
      proceed();
    } else if (value === INVOICE_PAYMENTS.CREDITS && selectedIndexes.length) {
      resetInvoicePayments(proceed);
    } else {
      proceed();
    }
  };

  const onChangePayment = (values, value) => {
    const proceed = async () => {
      if (!value) return;
      const payment = generatePayment(toNumber(value));
      await sleep(50);
      if (isEdit) {
        setInvoiceStates(adjustPaymentAmount(invoices, store, payment));
        return;
      }
      //const result = autoDistribute(invoices, selectedIndexes, payment);
      setInvoiceStates(adjustPaymentAmount(invoices, store, payment));
    };
    if (toNumber(values.amount) === toNumber(value)) return;
    proceed();
  };

  const alertFreezeCredit = () => {
    dialog.alert({
      title: intl('MESSAGE.ALERT'),
      message: intl('MESSAGE.CREDIT_FREEZE', { credit: currency(leftover) }),
    });
  };

  const onClickCreditDropdown = () => {
    if (freezeCredit) {
      alertFreezeCredit();
      return;
    }
    setShowCreditDropdown(true);
  };

  const paymentForm = PAYMENT_FORM(
    paymentType,
    storeCredits,
    onChangePayment,
    stores,
    factorySettings?.creditPaymentDropdown ? onClickCreditDropdown : null,
    onChangeStore,
    onPressPayment,
  );
  const creditForm = PAYMENT_CREDIT_FORM;
  const cashForm = PAYMENT_CASH_FORM;
  const checkForm = PAYMENT_CHECK_FORM(banks, paymentType === INVOICE_PAYMENTS.ACH);
  const otherForm = PAYMENT_MEMO_FORM;

  const onAutoFill = (amt, fieldName = 'amount') => {
    if (!formRef?.current) return;
    formRef.current.setFieldValue(fieldName, Math.max(amt, 0));
  };

  const onSelectInvoice = async (e, index, alreadySelected) => {
    e.stopPropagation();
    let newIndexes;
    if (alreadySelected) {
      newIndexes = selectedIndexes.filter((i) => i !== index);
    } else {
      newIndexes = [...selectedIndexes, index];
    }
    const result = addOrSubstractNewPayment({
      invoices,
      store,
      index,
      shouldAdd: !alreadySelected,
      paymentGroup,
      gPayment: generatePayment(),
    });
    await sleep(100);
    setInvoiceStates({ ...result, selectedIndexes: newIndexes });
    setTouched(true);
  };

  const resetForm = async (keepStore = false) => {
    await sleep(200);
    const values = {
      ...initialValues,
      ...(keepStore && { store: keepStore }),
    };
    if (formRef?.current) formRef.current.setValues({ ...values });
    const selector = '.payment-table .MuiTableBody-root .MuiTableRow-root';
    let el = getEl(selector + '.selected');
    if (el) el.classList.remove('selected');
    el = getEl('input[name=amount]');
    if (el) el.focus();
    setValidPayment(false);
    setPaymentConsumed(0);
    formRef?.current.setTouched({});
  };

  const deleteBank = () => {
    dialog.confirm({
      message: 'MESSAGE.DELETE_WARNING',
      onPress: async () => {
        const bankId = formValues?.bankId;
        await dispatch(factoryActions.deleteObj(bankId, configs.api.bank, factoryActions.fetchBankF));
        if (formRef.current) formRef.current.setFieldValue('bankId', '0');
      },
    });
  };

  const onSubmitBank = (name) => {
    dispatch(factoryActions.saveObj(0, { name }, configs.api.bank, factoryActions.fetchBankF));
  };

  const onClickPaymentEdit = async (row) => {
    if (!selectedIndexes.length) {
      onChangePaymentGroup(row);
      return;
    }
    await onChangePaymentGroup(null);
    await resetForm(false);
    await setTouched(false);
    // refreshInvoices(null);
  };

  const onChangeMultiPayment = async (row, value) => {
    const result = onChangeNewPaymentAmount({
      invoices,
      paymentGroup,
      invoice: row,
      value,
      store,
      gPayment: generatePayment(),
    });
    setInvoiceStates({
      ...result,
    });
    setTouched(true);
  };

  const changeDiscountRate = (e) => {
    const newRate = toNumber(e.target.value) || 0;
    const value = Math.round(total.subtotal * newRate) / 100;
    onAutoFill(value);
  };

  const onSubmit = async (values, shouldClose = false) => {
    const pgParam = generatePayment();

    const totals = invoices
      .filter((inv) => inv.newPayment || inv.deletePaymentId)
      .map((inv) => ({
        ...inv.totals,
        newPayment:
          {
            ...pgParam,
            amount: inv.newPayment?.amount,
            ...(inv.newPayment?.id && {
              id: inv.newPayment?.id,
            }),
          } ||
          inv.newPayment ||
          null,
        deletePaymentId: inv.deletePaymentId,
        invoiceId: inv.id,
      }));

    const newCredit = paymentType === INVOICE_PAYMENTS.CREDITS ? calculateConsumed(selectedInvoices) : 0;
    const param = {
      invoices: totals,
      ...(paymentGroup
        ? {
            paymentGroup: pgParam,
            isEdit: true,
          }
        : {}),
      storeId,
      ...(totals.length <= 0 && {
        paymentGroup: { ...pgParam, openBalance: pgParam.amount },
      }),
      usedCreditId: isCredits ? selectedCredit : 0,
      usedPaymentId: isCredits ? selectedPayment : 0,
      newCredits: newCredit,
      leftover: paymentType === INVOICE_PAYMENTS.DISCOUNT ? 0 : leftover,
      storeCredits,
    };

    // console.log('Param', param, storeCredits, newCredit);
    // return;

    await dispatch(invoiceActions.saveInvoicePayment(param)).then(async (updatedStore) => {
      if (!updatedStore) return;
      await resetForm(false);
      await setTouched(false);
      setNewCredit(0);

      onRefreshInvoice();
      if (shouldClose) {
        refreshInvoices();
        onClose();
      } else {
        setStore(deriveRawToStore(updatedStore));
        refreshInvoices();
        onChangePaymentGroup(null);
        await setTouched(false);
      }
    });
  };

  const viewCredits = () => {
    setShowCredits(true);
  };

  const onSelectCredits = async (creditId, paymentId, totalCredits) => {
    onPressPayment(totalCredits);
    formRef.current.setFieldValue('creditAmount', totalCredits);
    setSelectedCredit(creditId);
    setSelectedPayment(paymentId);
    const values = {
      ...formRef.current.values,
      creditAmount: totalCredits,
    };
    onChangePayment(values, totalCredits);
  };

  const generateToggleButton = (key) => {
    const disabled = !isEdit
      ? false
      : isDcMode || key === INVOICE_PAYMENTS.CREDITS || key === INVOICE_PAYMENTS.DISCOUNT;
    return (
      <ToggleButton
        key={key}
        value={INVOICE_PAYMENTS[key]}
        disabled={disabled}
        className={key === INVOICE_PAYMENTS.CREDITS ? accessDisable(ACCESS.ACTION.PAYMENT.CREDIT) : ''}>
        {key === INVOICE_PAYMENTS.CASH && <LocalAtm />}
        {key === INVOICE_PAYMENTS.CREDIT && <CreditCard />}
        {key === INVOICE_PAYMENTS.CHECK && <AccountBalance />}
        {key === INVOICE_PAYMENTS.ACH && <AccountBalance />}
        {key === INVOICE_PAYMENTS.CREDITS && <AttachMoney />}
        {key === INVOICE_PAYMENTS.DISCOUNT && <Discount />}
        {intl('INPUT.OPTION.PAYMENT_TYPE.' + key)}
      </ToggleButton>
    );
  };

  const validateForm = (values) => {
    setTouched(true);
    setInvoiceStates({ invoices: onChangePaymentForm(invoices, getFormValues(values), paymentGroup) });
    return input.validateError(paymentForm, { ...values });
  };

  // const storeInvoiceMismatch = invoice.storeId !== storeId;
  const tableConfig: TableModel = INVOICE_SIMPLE_TABLE_CONFIG(
    paymentGroup?.id,
    invoiceId,
    false,
    paymentGroupMode,
  );

  // console.log('#INVOICES', invoices, paymentGroup);

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => {
        const paymentAmount = toNumber(
          paymentType === INVOICE_PAYMENTS.CREDITS ? formik.values.creditAmount : formik.values.amount,
        );
        const paymentMode = !!(selectedIndexes.length && paymentAmount);
        const invalidLeftover = leftover < 0;
        const hasNegativeBal = !!selectedInvoices.find((inv) => inv.totals.balance < 0);
        const shouldDisableForm =
          paymentAmount <= 0 ||
          !touched ||
          freezeCredit ||
          (!selectedIndexes.length && isDcMode) ||
          invalidLeftover ||
          hasNegativeBal;

        return (
          <>
            <IonContentWrapper>
              <div className="fullscreen">
                <PageWrapper className="form-container">
                  <Row className="payment-types">
                    <Col gridSize={8} className="payments">
                      <ToggleButtonGroup
                        color="primary"
                        exclusive
                        value={paymentType}
                        onChange={onChangePaymentType}>
                        {[
                          PAYMENT_TYPE.CASH,
                          PAYMENT_TYPE.CREDIT,
                          PAYMENT_TYPE.CHECK,
                          PAYMENT_TYPE.ACH,
                          PAYMENT_TYPE.OTHER,
                        ].map(generateToggleButton)}
                      </ToggleButtonGroup>
                    </Col>
                    <Col gridSize={4} className="discounts">
                      <ToggleButtonGroup
                        color="primary"
                        exclusive
                        value={paymentType}
                        onChange={onChangePaymentType}>
                        {[INVOICE_PAYMENTS.DISCOUNT, INVOICE_PAYMENTS.CREDITS].map(generateToggleButton)}
                      </ToggleButtonGroup>
                    </Col>
                  </Row>

                  <div className="form-section">
                    <FormSection FORM={paymentForm} formik={formik} marginBottom="10px" />

                    <div className="extra-form">
                      <div className="pull-right credits-section">
                        {isDcMode && (
                          <Link onClick={viewCredits} className="view-credit">
                            View Credit History
                          </Link>
                        )}
                        {isAccessible(ACCESS.MONEY.VIEW, currentState) && (
                          <Flex justifyContent="flex-end">
                            <div>
                              <div className="credits">
                                {intl('SCREEN.CREDIT.BALANCE')}: <span>{currency(storeCredits)}</span>
                              </div>
                              <div className="open-balance">
                                {intl('SCREEN.STORES.COLS.OPEN_BALANCE')}:{' '}
                                <span>{currency(openBalance)}</span>
                              </div>
                            </div>
                          </Flex>
                        )}
                      </div>

                      <Row className="extra-line">
                        <Col gridSize={10}>
                          {paymentType === INVOICE_PAYMENTS.CASH && (
                            <FormSection FORM={cashForm} formik={formik} marginBottom="0" />
                          )}
                          {paymentType === INVOICE_PAYMENTS.CREDIT && (
                            <FormSection FORM={creditForm} formik={formik} marginBottom="0" />
                          )}
                          {(paymentType === INVOICE_PAYMENTS.CHECK ||
                            paymentType === INVOICE_PAYMENTS.ACH) && (
                            <Row className="bank-form">
                              <Col gridSize={7}>
                                <FormSection FORM={checkForm} formik={formik} marginBottom="0" />
                              </Col>
                              <Col gridSize={5} className="bank-buttons">
                                <Button
                                  color="primary"
                                  size="small"
                                  variant="outlined"
                                  className="remaining-button"
                                  icon={<Add />}
                                  onClick={() => setBank(initialBank)}>
                                  {intl('ITEM.ADD', { item: 'Bank' })}
                                </Button>
                                {formik.values?.bankId > 0 && (
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    className="remaining-button outlined danger"
                                    onClick={deleteBank}>
                                    Delete Selected Bank
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          )}
                          {paymentType === INVOICE_PAYMENTS.OTHER && (
                            <FormSection FORM={otherForm} formik={formik} marginBottom="0" />
                          )}
                          {isDcMode && (
                            <Row className="empty-form">
                              {paymentType === INVOICE_PAYMENTS.DISCOUNT ? (
                                <Col className="discount-selection" gridSize={12}>
                                  Apply
                                  <NativeSelect color="secondary" onChange={changeDiscountRate}>
                                    {generateDiscountSelection()}
                                  </NativeSelect>
                                  Discount
                                </Col>
                              ) : (
                                <div />
                              )}
                            </Row>
                          )}
                        </Col>
                        {/* <Col gridSize={2} className="add-button-wrapper">
                          <Button className="add-button" onClick={onAddPayment} disabled={invalidState}>
                            {intl(isEdit ? 'BUTTON.UPDATE' : 'BUTTON.ADD')}
                          </Button>
                        </Col> */}
                      </Row>
                    </div>
                  </div>

                  <Flex justifyContent="space-between">
                    <h4>{intl('SCREEN.INVOICE_PAYMENT.OPEN_INVOICES')}</h4>
                    <div className="payment-state">
                      {!!(paymentMode || paymentConsumed) && (
                        <>
                          {paymentConsumed > 0 && (
                            <div className="leftover consumed">
                              {intl('SCREEN.INVOICE_PAYMENT.CONSUMED')}:
                              <span className={`outlined`}>{currency(paymentConsumed)}</span>
                            </div>
                          )}
                          <div className="leftover consumed">
                            {intl('SCREEN.INVOICE_PAYMENT.LEFTOVER')}:
                            <span
                              className={`outlined ${
                                leftover === 0 ? 'success' : leftover < 0 ? 'danger' : ''
                              }`}>
                              {currency(leftover)}
                            </span>
                          </div>
                        </>
                      )}
                      {/* <div>
                        FREEZE:{freezeCredit.toString()} - <span>&nbsp;{paymentConsumed}</span>{' '}
                        <span>&nbsp;{leftover}</span>
                      </div> */}
                    </div>
                  </Flex>
                  <Div height={`${tableConfig.styles.headerHeight}px`}></Div>
                </PageWrapper>

                <TableView2
                  className="payment-table"
                  INNER_TABLE={INNER_PAYMENTS_TABLE({ ACCESS, onEditPayment: onClickPaymentEdit })}
                  data={invoices}
                  TABLE={INVOICE_SIMPLE_TABLE({
                    onSelectInvoice,
                    onChangeMultiPayment,
                    selectedIndexes,
                    leftover,
                    amount: toNumber(paymentAmount) - paymentConsumed,
                    onPaymentInputError,
                    validPayment,
                    paymentGroupId: paymentGroup?.id || 0,
                  })}
                  tableConfig={tableConfig}
                />
              </div>
            </IonContentWrapper>
            {isAccessible(ACCESS.MONEY.VIEW, currentState) && (
              <Footer className="no-print payment-footer">
                <Row flex={1}>
                  <Col gridSize={5} className="total-wrapper">
                    {isMultiInvoice && <MultiInvoiceSummary invoices={selectedInvoices} />}
                    {numSelected === 1 && <PaymentSummary total={total} />}
                  </Col>
                  <Col gridSize={7} className="flex">
                    <Flex width="100%" justifyContent="space-around">
                      <Button
                        className={`third ${accessDisable(ACCESS.ACTION.PAYMENT.CREATE)}`}
                        onClick={() => onSubmit(formik.values, true)}
                        disabled={shouldDisableForm}>
                        {intl('BUTTON.SAVE_CLOSE')}
                      </Button>
                      <Button
                        className={`third ${accessDisable(ACCESS.ACTION.PAYMENT.CREATE)}`}
                        onClick={() => onSubmit(formik.values, false)}
                        disabled={shouldDisableForm}>
                        {intl('BUTTON.SAVE')}
                      </Button>
                    </Flex>
                  </Col>
                </Row>
              </Footer>
            )}
            <BankInput setBank={setBank} bank={bank} submitBank={onSubmitBank} />
            <CreditHistoryModal
              width="760px"
              show={showCredits}
              onClose={() => setShowCredits(false)}
              store={store}
            />
            <CreditDropdown
              show={showCreditDropdown}
              disabledCredits={[]}
              onClose={() => setShowCreditDropdown(false)}
              onFinish={onSelectCredits}
              store={store}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default PaymentDetails;
